import {
  cowMats,
  freeStalls,
  gates,
  headlocks,
  ventilation,
} from "../../../blocks/dashboard/src/assets";

export const STALL_SYSTEM: {
  key: string;
  title: string;
}[] = [
  { key: "suspended", title: "Suspended" },
  { key: "stand_post", title: "Stand Post" },
  { key: "universal", title: "Universal" },
];

export const STALL_LAYOUT: {
  key: string;
  title: string;
}[] = [
  { key: "head_to_head", title: "Head-to-Head" },
  { key: "outside_row", title: "Outside Row" },
];

export const POST_TYPE: {
  key: string;
  title: string;
}[] = [
  { key: "wood", title: "Wood" },
  { key: "steel", title: "Steel" },
];

export const HEAD_TO_HEAD: {
  key: string;
  title: string;
}[] = [
  { key: "centered", title: "Centered" },
  { key: "offset", title: "Offset" },
];

export const INTERNAL_TRAVEL_LANE: {
  key: string;
  title: string;
}[] = [
  { key: "yes", title: "Yes" },
  { key: "no", title: "No" },
];

export const TRAVEL_LANE_POSITION: {
  key: string;
  title: string;
}[] = [
  { key: "center", title: "Center" },
  { key: "end", title: "End" },
];

export const TRAVEL_LANE_POSITION_3: {
  key: string;
  title: string;
}[] = [
  { key: "center", title: "Centered in the Travel Lane" },
  { key: "end", title: "End of the Stall Alley" },
  { key: "neither", title: "Neither" },
];

export const CATEGORIES = [
  {
    key: "ventilation",
    title: "Ventilation",
    description: "lorem ipsum",
    imageUrl: ventilation,
  },
  {
    key: "free-stalls",
    title: "Free Stalls",
    description: "lorem ipsum",
    imageUrl: freeStalls,
  },
  {
    key: "gates-panels",
    title: "Gates/Panels",
    description: "lorem ipsum",
    imageUrl: gates,
  },
  {
    key: "headlocks-slant-bars",
    title: "Headlocks/Slant bars",
    description: "lorem ipsum",
    imageUrl: headlocks,
  },
  {
    key: "cow-mats",
    title: "Cow Mats",
    description: "lorem ipsum",
    imageUrl: cowMats,
  },
];
