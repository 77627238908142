import Grid from "@mui/material/Grid";
import { MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from "@mui/styles";
import { Label } from "../ui/label";
import { CustomCounter } from "../dashboard/Counter";
import { Input } from "../ui/input";
import { FieldContainer, HorizontalField, SelectField, Step1Container } from "./style";
import React, { ChangeEvent } from "react";
import { Colors } from "../../../blocks/utilities/src/Colors";

interface Props {
  values: any;
  handleChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  cowNumber: number;
  handleCowNumberChange: (cowNum: number) => void;
}

const StyledSelect = styled(Select)({
  width: "50%",
  borderRadius: "8px",
  border: `1px solid ${Colors.lightGray}`,
  "& .MuiSelect-select": {
    padding: "12px 8px",
  },
  "@media(max-width: 768px)": {
    width: "100%",
    marginTop: "8px"
  }
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 400,
  color: Colors.textPrimary,
})

export default function FirstStepForm({
  values,
  handleChange,
  cowNumber,
  handleCowNumberChange,
}: Props) {
  return (
    <Step1Container>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FieldContainer>
            <HorizontalField>
              <Label style={{ marginBottom: 0, flex: 1 }}>
                Enter the number of cows (Optional)
              </Label>
              <CustomCounter
                value={cowNumber}
                onChange={handleCowNumberChange}
              />
            </HorizontalField>
          </FieldContainer>
        </Grid>
        <Grid item xs={12}>
          <FieldContainer>
            <SelectField>
              <Label style={{ marginBottom: 0, flex: 1 }}>
                Enter the design barn type
              </Label>
              <StyledSelect
                name={"barnType"}
                value={values.barnType || "4_row"}
                onChange={(e: SelectChangeEvent<any>) => handleChange(e)}
                displayEmpty
              >
                <StyledMenuItem value="4_row">4 Row Barn</StyledMenuItem>
                <StyledMenuItem value="6_row">6 Row Barn</StyledMenuItem>
              </StyledSelect>
            </SelectField>
          </FieldContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldContainer>
            <Label>Enter the Barn Length in feet</Label>
            <Input
              type="text"
              value={values.internal_travel_lane}
              onChange={handleChange}
              placeholder="Enter value"
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldContainer>
            <Label>Enter the Barn Width in feet</Label>
            <Input
              type="text"
              value={values.internal_travel_lane}
              onChange={handleChange}
              placeholder="Enter value"
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldContainer>
            <Label>Enter the Barn Eave Height in feet</Label>
            <Input
              type="text"
              value={values.internal_travel_lane}
              onChange={handleChange}
              placeholder="Enter value"
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldContainer>
            <Label>Barn Pole Spacing</Label>
            <Input
              type="text"
              value={values.internal_travel_lane}
              onChange={handleChange}
              placeholder="Enter value"
            />
          </FieldContainer>
        </Grid>
      </Grid>
    </Step1Container>
  );
}
