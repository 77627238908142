import React, { useState } from "react";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { styled } from "@mui/styles";
import {
  FieldContainer,
  SelectField,
  CheckboxItemContainer,
  CheckboxItem,
  CheckboxText,
  PreviewDesignHeader,
} from "./style";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { STALL_LAYOUT, STALL_SYSTEM } from "../constants/categories";
import { Checkbox } from "../ui/input/checkbox";

const MaximizeButton = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9C7 9.55 6.55 10 6 10C5.45 10 5 9.55 5 9V6C5 5.45 5.45 5 6 5H9C9.55 5 10 5.45 10 6C10 6.55 9.55 7 9 7H7V9ZM5 15C5 14.45 5.45 14 6 14C6.55 14 7 14.45 7 15V17H9C9.55 17 10 17.45 10 18C10 18.55 9.55 19 9 19H6C5.45 19 5 18.55 5 18V15ZM17 17H15C14.45 17 14 17.45 14 18C14 18.55 14.45 19 15 19H18C18.55 19 19 18.55 19 18V15C19 14.45 18.55 14 18 14C17.45 14 17 14.45 17 15V17ZM15 7C14.45 7 14 6.55 14 6C14 5.45 14.45 5 15 5H18C18.55 5 19 5.45 19 6V9C19 9.55 18.55 10 18 10C17.45 10 17 9.55 17 9V7H15Z"
      fill="#0F172A"
    />
  </svg>
);

const MinimizeButton = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8H8V6C8 5.45 8.45 5 9 5C9.55 5 10 5.45 10 6V9C10 9.55 9.55 10 9 10H6C5.45 10 5 9.55 5 9C5 8.45 5.45 8 6 8ZM8 16H6C5.45 16 5 15.55 5 15C5 14.45 5.45 14 6 14H9C9.55 14 10 14.45 10 15V18C10 18.55 9.55 19 9 19C8.45 19 8 18.55 8 18V16ZM15 19C15.55 19 16 18.55 16 18V16H18C18.55 16 19 15.55 19 15C19 14.45 18.55 14 18 14H15C14.45 14 14 14.45 14 15V18C14 18.55 14.45 19 15 19ZM16 6V8H18C18.55 8 19 8.45 19 9C19 9.55 18.55 10 18 10H15C14.45 10 14 9.55 14 9V6C14 5.45 14.45 5 15 5C15.55 5 16 5.45 16 6Z"
      fill="#0F172A"
    />
  </svg>
);

const Hamburger = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C3.45 8 3 7.55 3 7C3 6.45 3.45 6 4 6H20C20.55 6 21 6.45 21 7C21 7.55 20.55 8 20 8H4ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18Z"
      fill="#0F172A"
    />
  </svg>
);

const DesignMinimizeButton = () => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 19H7"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 19H11.5"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 16H7"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 16H11.5"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 19H16"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 19H20.5"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 16H16"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 16H20.5"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 13C16.3137 13 19 10.3137 19 7C19 3.68629 16.3137 1 13 1C9.68629 1 7 3.68629 7 7C7 10.3137 9.68629 13 13 13Z"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 4V10"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7H16"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.562 9.45508L1.75 11.5181V22.7491H24.25V11.5181L21.437 9.45508"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="#D1FAE5" />
    <path
      d="M14.7946 21.8754L11.3246 18.4054C11.1377 18.2181 10.8841 18.1129 10.6196 18.1129C10.3551 18.1129 10.1014 18.2181 9.91457 18.4054C9.52457 18.7954 9.52457 19.4254 9.91457 19.8154L14.0946 23.9954C14.4846 24.3854 15.1146 24.3854 15.5046 23.9954L26.0846 13.4154C26.4746 13.0254 26.4746 12.3954 26.0846 12.0054C25.8977 11.8181 25.6441 11.7129 25.3796 11.7129C25.1151 11.7129 24.8614 11.8181 24.6746 12.0054L14.7946 21.8754Z"
      fill="#059669"
    />
  </svg>
);

const CrossIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#FEE2E2" />
    <path
      d="M22.2997 9.71069C22.1129 9.52344 21.8592 9.4182 21.5947 9.4182C21.3302 9.4182 21.0766 9.52344 20.8897 9.71069L15.9997 14.5907L11.1097 9.70069C10.9229 9.51344 10.6692 9.4082 10.4047 9.4082C10.1402 9.4082 9.88656 9.51344 9.69973 9.70069C9.30973 10.0907 9.30973 10.7207 9.69973 11.1107L14.5897 16.0007L9.69973 20.8907C9.30973 21.2807 9.30973 21.9107 9.69973 22.3007C10.0897 22.6907 10.7197 22.6907 11.1097 22.3007L15.9997 17.4107L20.8897 22.3007C21.2797 22.6907 21.9097 22.6907 22.2997 22.3007C22.6897 21.9107 22.6897 21.2807 22.2997 20.8907L17.4097 16.0007L22.2997 11.1107C22.6797 10.7307 22.6797 10.0907 22.2997 9.71069Z"
      fill="#DC2626"
    />
  </svg>
);

const sharedStyle = {
  display: "flex",
  alignItems: "center",
  padding: "1rem",
  backgroundColor: Colors.white,
};

const IconContainer = styled("div")({
  ...sharedStyle,
  justifyContent: "space-between",
  marginBottom: "1rem",
});

const SingleIconContainer = styled("div")({
  ...sharedStyle,
  justifyContent: "center",
});

export default function CustomizationScreen() {
  const options = [
    {
      title: "Ventilation",
      content: null,
      completed: false,
    },
    {
      title: "Free Stalls",
      content: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldContainer>
              <SelectField>
                <Label>Choose Stall System</Label>
                <CheckboxItemContainer>
                  {STALL_SYSTEM.map((item) => (
                    <CheckboxItem key={item.key}>
                      <Checkbox
                        name="stall_system"
                        checked={false}
                        value={item.key}
                        onChange={() => {}}
                      />
                      <CheckboxText>{item.title}</CheckboxText>
                    </CheckboxItem>
                  ))}
                </CheckboxItemContainer>
              </SelectField>
            </FieldContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldContainer>
              <Label>Stall spacing (in inches)</Label>
              <Input
                type="text"
                value={48}
                onChange={() => {}}
                placeholder="Enter value"
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldContainer>
              <Label>Length of Beds (in inches)</Label>
              <Input
                type="text"
                value={48}
                onChange={() => {}}
                placeholder="Enter value"
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FieldContainer>
              <SelectField>
                <Label>Stall Layout</Label>
                <CheckboxItemContainer>
                  {STALL_LAYOUT.map((item) => (
                    <CheckboxItem key={item.key}>
                      <Checkbox
                        name="stall_system"
                        checked={false}
                        value={item.key}
                        onChange={() => {}}
                      />
                      <CheckboxText>{item.title}</CheckboxText>
                    </CheckboxItem>
                  ))}
                </CheckboxItemContainer>
              </SelectField>
            </FieldContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldContainer>
              <Label>Post spacing (in inches)</Label>
              <Input
                type="text"
                value={48}
                onChange={() => {}}
                placeholder="Enter value"
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldContainer>
              <Label>Curb height (in inches)</Label>
              <Input
                type="text"
                value={48}
                onChange={() => {}}
                placeholder="Enter value"
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FieldContainer>
              <SelectField>
                <Label>Stall Layout</Label>
                <CheckboxItemContainer>
                  {STALL_LAYOUT.map((item) => (
                    <CheckboxItem key={item.key}>
                      <Checkbox
                        name="stall_system"
                        checked={false}
                        value={item.key}
                        onChange={() => {}}
                      />
                      <CheckboxText>{item.title}</CheckboxText>
                    </CheckboxItem>
                  ))}
                </CheckboxItemContainer>
              </SelectField>
            </FieldContainer>
          </Grid>
        </Grid>
      ),
      completed: true,
    },
    {
      title: "Gates/Panels",
      content: <p>Content for Gates/Panels</p>,
      completed: false,
    },
    {
      title: "Cow Mats",
      content: <p>Content for Cow Mats</p>,
      completed: false,
    },
  ];
  const [fullScreen, setCrView] = useState("");

  const toggleFullScreen = (side: string) => {
    setCrView((prev) => (prev === side ? "" : side));
  };

  return (
    <Grid
      container
      direction={useMediaQuery("(min-width:600px)") ? "row" : "column-reverse"}
      style={{ flexWrap: "nowrap", gap: "1rem" }}
    >
      {/* Equipment Customization */}
      <Grid
        item
        xs={
          fullScreen === "customization" ? 11 : fullScreen === "design" ? 1 : 4
        }
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          transition: "all 0.3s ease",
          height:
            fullScreen === "customization"
              ? "auto"
              : fullScreen === "design"
              ? "70px"
              : "auto",
        }}
      >
        {fullScreen === "design" ? (
          <SingleIconContainer
            onClick={() => toggleFullScreen("customization")}
          >
            <Hamburger />
          </SingleIconContainer>
        ) : (
          <>
            <IconContainer>
              <div style={{ fontWeight: 700 }}>Equipment Customization</div>
              <div onClick={() => toggleFullScreen("customization")}>
                {fullScreen === "customization" ? (
                  <MinimizeButton />
                ) : (
                  <MaximizeButton />
                )}
              </div>
            </IconContainer>
            <div style={{ marginTop: "16px" }}>
              {options.map((option, index) => (
                <Accordion
                  key={index}
                  id={`panel${index}`}
                  aria-controls={`panel${index}-content`}
                  style={{ marginBottom: "1rem", boxShadow: "none" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {option.completed ? <CheckIcon /> : <CrossIcon />}
                        <p>{option.title}</p>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      backgroundColor: Colors.bgPrimary,
                      margin: "8px",
                      padding: "8px",
                    }}
                  >
                    {option.content || <p>No Content</p>}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </>
        )}
      </Grid>

      {/* Design Preview */}
      <Grid
        item
        xs={
          fullScreen === "design" ? 11 : fullScreen === "customization" ? 1 : 8
        }
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          transition: "all 0.3s ease",
          height:
            fullScreen === "design"
              ? "auto"
              : fullScreen === "customization"
              ? "70px"
              : "auto",
        }}
      >
        {fullScreen === "customization" ? (
          <SingleIconContainer onClick={() => toggleFullScreen("design")}>
            <DesignMinimizeButton />
          </SingleIconContainer>
        ) : (
          <>
            <IconContainer>
              <PreviewDesignHeader>Hotspot feature you want to edit</PreviewDesignHeader>
              <div onClick={() => toggleFullScreen("design")}>
                {fullScreen === "design" ? (
                  <MinimizeButton />
                ) : (
                  <MaximizeButton />
                )}
              </div>
            </IconContainer>
          </>
        )}
      </Grid>
    </Grid>
  );
}