// Customizable Area Start
import React from "react";
import NewBarnQuoteModalController, {
  Props,
  // @ts-ignore
} from "./NewBarnQuoteModalController";
import { ModalCore } from "../ui/modal";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { Radio } from "../ui/input/checkbox";
import { styled } from "@mui/styles";
import Divider from "@mui/material/Divider";
// @ts-ignore
import { ChooseClientDropdown } from "./dropdown/ChooseClientDropdown";
import AddClientModal from "./AddClientModal";
import { Colors } from "../../../blocks/utilities/src/Colors";

// Customizable Area End
const StyledButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "24px",
});

const StyledLabel = styled("label")({
  display: "flex",
  alignItems: "center",
  marginTop: "12px",
});

const Block = styled("div")({
  marginTop: "16px",
});

export default class NewBarnQuoteModal extends NewBarnQuoteModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, closeModal, navigation } = this.props;
    const { addModalOpen, selectedOption } = this.state;

    return (
      <ModalCore open={open} handleClose={closeModal} title="New barn quote">
        <>
          <Divider />
          <Block>
            <Label>Select option you want to create</Label>
            <StyledLabel>
              <Radio
                name="option"
                value="quick-quote"
                onChange={this.handleOptionChange}
              />
              <span style={{ color: Colors.textPrimary }}>Quick Quote</span>
            </StyledLabel>
            <StyledLabel>
              <Radio
                name="option"
                value="customize-your-own"
                onChange={this.handleOptionChange}
              />
              <span style={{ color: Colors.textPrimary }}>
                Customize Your Own
              </span>
            </StyledLabel>
          </Block>
          <Block>
            <Label>Choose client or create new</Label>
            <ChooseClientDropdown onCreateNewClient={this.openModal} />
          </Block>

          <AddClientModal
            open={addModalOpen}
            closeModal={this.closeModal}
            navigation={navigation}
          />

          <Divider />

          <StyledButtonContainer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.redirectToCustomization} disabled={!selectedOption}>
              Next
            </Button>
          </StyledButtonContainer>
        </>
      </ModalCore>
    );
  }
}
