import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ExistingQuotes } from "../../../components/src/dashboard/types";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  data: ExistingQuotes[];
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  filters: {
    status: string[],
    barnType: string[],
    projectType: string[],
  }
  // Customizable Area End
}
interface SS {}

export default class ProjectTableViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      filters: {
        status: [],
        barnType: [],
        projectType: [],
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleFilterChange = (newFilters: any) => {
    // istanbul ignore next
    this.setState({ filters: newFilters });
  };
  // Customizable Area End
}
