import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest } from "../../../components/src/utils/api";
// @ts-ignore
import { getFromStorage } from "../../../components/src/utils/string";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export const globalConfig = require("../../../framework/src/config");

export interface Props {
  navigation: any;
}
interface S {
  // Customizable Area Start
  isEditMode: boolean;
  isTermsModalOpen: boolean;
  isPrivacyModalOpen: boolean;
  isToastOpen: boolean;
  policyText: string;
  isSubmitting: boolean;
  message: string;
  userDetail: any;
  // Customizable Area End
}
interface SS {}

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  policyRequestId: any;
  getPersonalDetailsRequestId: any;
  updatePersonalDetailsRequestId: any;
  uploadAvatarRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isEditMode: false,
      isTermsModalOpen: false,
      isPrivacyModalOpen: false,
      isToastOpen: false,
      policyText: "",
      isSubmitting: false,
      message: "",
      userDetail: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleGetUserDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          return;
        }

        switch (apiRequestCallId) {
          case this.policyRequestId:
            this.setState({
              policyText: responseJson.data?.attributes?.description,
            });
            break;
          case this.updatePersonalDetailsRequestId:
            this.setState({ isSubmitting: false, isEditMode: false });
            this.props.navigation.navigate("Profile");
            break;
          case this.uploadAvatarRequestId:
            this.setState({
              message: responseJson.message,
              isEditMode: false,
              isToastOpen: true,
            });
            break;
          case this.getPersonalDetailsRequestId:
            this.setState({ userDetail: responseJson.data?.attributes });
            break;
        }
      }
    }
    // Customizable Area End
  }

  handleGetPolicy = (type: string) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.policyRequestId = requestMessage.messageId;

    if (type === "privacy") {
      this.setState({ isPrivacyModalOpen: true });
    } else {
      this.setState({ isTermsModalOpen: true });
    }

    createRequest({
      requestMsg: requestMessage,
      endPoint: `${configJSON.policyEndpoint}/${
        type === "privacy" ? "1" : "2"
      }`,
      method: "GET",
    })
  }

  handleEditPersonalDetails = (values: any) => {
    this.setState({ isSubmitting: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePersonalDetailsRequestId = requestMessage.messageId;

    const payload = {
      data: {
        type: "accounts",
        id: getFromStorage("userId"),
        attributes: {
          role_type: 0,
          full_phone_number: values.phone,
          first_name: values.full_name,
          email: values.email,
          salesperson_code: values.salesperson_code,
        },
      },
    };

    createRequest({
      token: getFromStorage("token") ?? "",
      requestMsg: requestMessage,
      endPoint: `${configJSON.updatePersonalDetailsEndpoint}/${getFromStorage(
        "userId"
      )}`,
      method: "PATCH",
      body: JSON.stringify(payload),
    });
  };

  handleGetUserDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPersonalDetailsRequestId = requestMessage.messageId;

    createRequest({
      token: getFromStorage("token") ?? "",
      requestMsg: requestMessage,
      endPoint: `${configJSON.personalDetailsEndpoint}/${getFromStorage(
        "userId"
      )}`,
      method: "GET",
    });
  };

  handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    this.props.navigation.navigate("Login");
  };

  handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const avatarFile = e.target.files[0];

      const formData = new FormData();
      formData.append("profile_photo", avatarFile);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.uploadAvatarRequestId = requestMessage.messageId;

      createRequest({
        isFormDataRequest: true,
        requestMsg: requestMessage,
        endPoint: `${configJSON.personalDetailsEndpoint}/${getFromStorage(
          "userId"
        )}/upload_profile_photo`,
        method: "POST",
        body: formData,
      });
    }
  };

  closeToast = () => {
    this.setState({ isToastOpen: false });
  };

  openModal = (type: string) => {
    if (type === "privacy") {
      this.setState({ isPrivacyModalOpen: true });
    } else {
      this.setState({ isTermsModalOpen: true });
    }
  };

  closeModal = (type: string) => {
    if (type === "privacy") {
      this.setState({ isPrivacyModalOpen: false });
    } else {
      this.setState({ isTermsModalOpen: false });
    }
  };

  navigateToChangePasswordScreen = () => {
    this.props.navigation.navigate("ChangePassword");
  };
  // Customizable Area End
}
