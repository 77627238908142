import * as Yup from "yup";

export const signUpValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character"),
  salespersonCode: Yup.string().nullable(),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phone: Yup.string().nullable().matches(/^\d{10,12}$/, "Phone number must be between 10 and 12 digits"),
  terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

export const loginValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
});