import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest } from "../../../components/src/utils/api";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

interface QuoteConfiguration {
  number_of_cows: string;
  stall_system: string;
  stall_groups: string;
  stall_layout: string;
  stall_spacing_in_inches: string;
  length_of_beds: string;
  post_spacing: string;
  post_type: string;
  head_to_head_posts: string;
  curb_height: string;
  end_wall_height: string;
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  cowNumber: number;
  isLoading: boolean;
  isAddNewModalOpen: boolean;
  selectedCategory: string;
  // Customizable Area End
}
interface SS {}

export default class ProductCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  createQuoteRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeStep: 0,
      cowNumber: 150,
      isLoading: false,
      isAddNewModalOpen: false,
      selectedCategory: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          this.setState({
            isLoading: false,
          });
          return;
        }

        if (apiRequestCallId === this.createQuoteRequestId) {
          this.setState({
            isLoading: false,
            activeStep: this.state.activeStep + 1,
          });
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleMoveNext = (values: QuoteConfiguration) => {
    this.setState({ isLoading: true }, () => {
      this.createNewQuote(values);
    });
  };

  handleMoveBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleCowNumberChange = (newValue: number) => {
    this.setState({ cowNumber: newValue });
  };

  createNewQuote = (values: QuoteConfiguration) => {
    // Call API to create new quote
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createQuoteRequestId = requestMessage.messageId;

    const payload = {
      quote: {
        barn_type: "equipment",
        status: "in_progress",
        quote_type: "standard",
        client: "Client99",
        amount: 100000.0,
        equipment_type: this.props.navigation.getParam("category")?.replace(/-/g, "_"),
        configuration: {
          quote_configuration: {
            number_of_cows: this.state.cowNumber,
            stall_system: values.stall_system,
            stall_groups: values.stall_groups,
            stall_layout: values.stall_layout,
            stall_spacing_in_inches: values.stall_spacing_in_inches,
            length_of_beds: values.length_of_beds,
            post_spacing: values.post_spacing,
            post_type: values.post_type,
            head_to_head_posts: values.head_to_head_posts,
            curb_height: values.curb_height,
            end_wall_height: values.end_wall_height,
          },
        },
      },
    };

    createRequest({
      requestMsg: requestMessage,
      endPoint: webConfigJSON.getExistingQuotesEndpoint,
      method: "POST",
      body: JSON.stringify(payload),
    });
  };

  handleCloseAddModal = () => {
    this.setState({ isAddNewModalOpen: false });
  }

  handleAddNewEquipment = () => {
    this.props.navigation.navigate("ProductCategories", {
      path: {
        category: this.state.selectedCategory,
      }
    });
    this.setState({
      isAddNewModalOpen: false,
      activeStep: 0,
    });
  }

 handleSelectCategory = (category: string) => {
  this.setState((prevState) => ({
    selectedCategory: prevState.selectedCategory === category ? "" : category,
  }));
}
  // Customizable Area End
}
