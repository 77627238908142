import React, { useState } from "react";
import { TextField, Box } from "@mui/material";
import { Button } from "./../ui/button";

interface CounterProps {
  value: number
  onChange: (newValue: number) => void
}

export const CustomCounter = ({value, onChange}: CounterProps) => {
  const handleIncrease = () => {
    onChange(value + 1)
  };

  const handleDecrease = () => {
    onChange(value > 0 ? value -1 : 0)
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue)) {
      onChange(inputValue);
    } else if (event.target.value === "") {
      onChange(0);
    }
  };

  const handleBlur = () => {
    if (value === 0) {
      onChange(0);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        backgroundColor: "#E5EFF5",
        padding: "4px 8px",
        borderRadius: "8px",
      }}
    >
      <Button
        variant="secondary"
        onClick={handleDecrease}
        style={{ padding: "8px 12px" }}
      >
        -1
      </Button>
      <TextField
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        size="small"
        inputProps={{
          style: { textAlign: "center", width: "30px", padding: "4px" },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            backgroundColor: "transparent",
            padding: 0,
            outline: "none",
            border: "none",
            fontWeight: 700,
            fontFamily: "Inter",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
      <Button
        variant="secondary"
        onClick={handleIncrease}
        style={{ padding: "8px 12px" }}
      >
        +1
      </Button>
    </Box>
  );
};
