import React from "react";
// Customizable Area Start
import CatalogueController, { Props } from "./CatalogueController";
import DashboardLayout from "../../../components/src/dashboard/layout";
import { styled } from "@mui/styles";
import { Box } from '@mui/material';
import { Input } from '../../../components/src/ui/input';
import { InventoryItem } from '../../../components/src/catalogue/InventoryItem';
import { Colors } from '../../../blocks/utilities/src/Colors';
// Customizable Area End

// Styled components
const InventoryListContainer = styled(Box)({
  position: 'relative',
  minHeight: '400px',
  maxWidth: '64rem',
  margin: '0 auto',
  padding: '3rem 0',
  display: "flex",
  flexDirection: 'column',
  gap: "16px"
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { inventoryList, searchQuery } = this.state;

    const filteredInventoryList = inventoryList && inventoryList.filter(item =>
      item.attributes.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.attributes.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.attributes.no.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
      // Customizable Area Start
      <DashboardLayout>
        <main style={{ backgroundColor: Colors.bgPrimary, minHeight: '100vh' }}>
          <InventoryListContainer>
            <Input
              type="text"
              data-test-id="inventory-search"
              style={{ width: '50%', maxWidth: '400px' }}
              value={searchQuery}
              placeholder="Search by ID, name, or description..."
              onChange={(e) => this.handleSearch(e.target.value)}
            />
            {filteredInventoryList && filteredInventoryList.length > 0 ? (
              filteredInventoryList.map((item, index) => (
                <InventoryItem key={item.id} item={item} />
              ))
            ) : (
              <p
                style={{
                  position: 'absolute',
                  inset: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'text.secondary'
                }}
              >
                No items found matching your criteria
              </p>
            )}
          </InventoryListContainer>
        </main>
      </DashboardLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
