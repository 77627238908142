import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Colors } from "../../../../blocks/utilities/src/Colors";

const sharedTextStyles = {
  fontSize: "24px",
  color: Colors.textPrimary,
  "@media (max-width: 768px)": {
    fontSize: "18px",
  }
}

const useStyles = makeStyles({
  breadcrumbsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    overflow: "hidden",
  },
  breadcrumbsItem: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumbLink: {
    ...sharedTextStyles,
    textDecoration: "none",
  },
  breadcrumbText: {
    ...sharedTextStyles,
    fontWeight: 700,
  },
});

export const Breadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const styles = useStyles();
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={styles.breadcrumbsContainer}>
      <>
        {childrenArray.map((child, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div className={styles.breadcrumbsItem}>
                <ChevronRightIcon
                  style={{ color: Colors.textTertiary }}
                  aria-hidden="true"
                />
              </div>
            )}
            <div className={styles.breadcrumbsItem}>{child}</div>
          </React.Fragment>
        ))}
      </>
    </div>
  );
};

interface BreadcrumbLinkProps {
  label: string;
  href?: string;
}

export const BreadcrumbLink = ({ label, href }: BreadcrumbLinkProps) => {
  const styles = useStyles();
  return (
    <div>
      {href ? (
        <Link className={styles.breadcrumbLink} to={href}>
          {label}
        </Link>
      ) : (
        <p className={styles.breadcrumbText}>{label}</p>
      )}
    </div>
  );
};
