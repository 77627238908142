import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  badge: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'uppercase',
    borderRadius: '40px',
    padding: "4px 18px",
    letterSpacing: '0.5px',
  },
  sent: {
    color: "#2563EB",
    backgroundColor: "#DBEAFE"
  },
  in_progress: {
    color: '#D97706',
    backgroundColor: '#FEF3C7'
  },
  completed: {
    color: '#059669',
    backgroundColor: '#D1FAE5'
  },
})

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  variant: "sent" | "in_progress" | "completed"
}

const getLabelName = (variant: "sent" | "in_progress" | "completed") => {
  switch (variant) {
    case "sent":
      return "Sent"
    case "in_progress":
      return "In Progress"
    case "completed":
      return "Completed"
  }
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(({className, variant, label}, ref) => {
  const styles = useStyles();
  return (
    <div className={`${styles.badge} ${styles[variant]}`} ref={ref}>
      {getLabelName(variant)}
    </div>
  );
})

export { Badge };

Badge.displayName = 'Badge';