import React from "react";
// Customizable Area Start
// Customizable Area End
import ProductCategoriesController, {
  Props,
} from "./ProductCategoriesController";
import { styled } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Colors } from "../../utilities/src/Colors";
import { Label } from "../../../components/src/ui/label";
import { Button } from "../../../components/src/ui/button";
import { Input } from "../../../components/src/ui/input";
import { BaseStepper } from "../../../components/src/ui/stepper";
import {
  Breadcrumbs,
  BreadcrumbLink,
} from "../../../components/src/ui/breadcrumbs";
import { Checkbox } from "../../../components/src/ui/input/checkbox";
import DashboardLayout from "../../../components/src/dashboard/layout";
import {
  CATEGORIES,
  HEAD_TO_HEAD,
  POST_TYPE,
  STALL_LAYOUT,
  STALL_SYSTEM,
} from "../../../components/src/constants/categories";
import { CustomCounter } from "../../../components/src/dashboard/Counter";
import { Form, Formik } from "formik";
import { Spinner } from "../../../components/src/ui/spinner";
import {
  CheckboxItemContainer,
  CheckboxText,
  CheckboxItem,
  SelectField,
} from "../../../components/src/quote/style";
import { ModalCore } from "../../../components/src/ui/modal";
import { BaseSlider } from "../../../components/src/dashboard/BaseSlider";
import Divider from "@mui/material/Divider";

const ProductCategoriesContainer = styled("main")({
  padding: "30px 48px",
  minHeight: "100vh",
  backgroundColor: Colors.bgPrimary,
  "@media (max-width: 768px)": {
    padding: "30px 20px",
  },
});

const FieldContainer = styled("div")({
  backgroundColor: Colors.white,
  borderRadius: "8px",
  padding: "24px",
  margin: "0 12px",
  "@media(max-width: 768px)": {
    padding: "16px",
  },
});

const HorizontalField = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
});

const ButtonContainer = styled("div")({
  display: "flex",
  gap: "16px",
  width: "100%",
  marginTop: "24px",
  justifyContent: "flex-end",
});

const PageHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "32px",
  "@media (max-width: 768px)": {
    margin: "40px 0 24px",
  },
});

const StyledEquipment = styled("span")({
  backgroundColor: Colors.btnPrimary,
  color: Colors.white,
  borderRadius: "8px",
  padding: "5px 10px",
});

const PdfItemContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  backgroundColor: Colors.white,
  borderRadius: "8px 24px 8px 8px",
  marginBottom: "24px",
});

const PdfName = styled("p")({
  fontSize: "32px",
  fontWeight: 700,
  color: Colors.textTertiary,
});

const AddEquipmentIconBlock = styled("div")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
  },
});

const StyledSlider = styled("div")({
  margin: "1rem 0",
  "& .slick-list": {
    width: "100%",
  },
  "& .slick-slide > div": {
    marginRight: "16px",
  },
});

const CategoryItem = styled("div")({
  boxSizing: "border-box",
});

const CategoryImage = styled("img")({
  width: "100%",
  height: "auto",
  aspectRatio: "3/4",
  borderRadius: "10px",
});

const CategoryText = styled("div")({
  margin: "8px 0",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

const CategoryTitle = styled("h4")({
  color: Colors.textPrimary,
  fontSize: "14px",
  fontWeight: 700,
  marginBottom: "4px",
  textOverflow: "ellipsis",
});

const CategoryDescription = styled("p")({
  color: Colors.textTertiary,
  fontSize: "14px",
});

const AddEquipmentText = styled("span")({
  display: "block",
  "@media (max-width: 768px)": {
    display: "none",
  },
});

const AddEquipmentIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.001 3H15.001C14.451 3 13.9801 3.19583 13.5885 3.5875C13.1968 3.97917 13.001 4.45 13.001 5V19C13.001 19.55 13.1968 20.0208 13.5885 20.4125C13.9801 20.8042 14.451 21 15.001 21H19.001C19.551 21 20.0218 20.8042 20.4135 20.4125C20.8051 20.0208 21.001 19.55 21.001 19V11H19.001V19H15.001V5H16.001V3Z"
      fill="white"
    />
    <path
      d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9C9.55 3 10.0208 3.19583 10.4125 3.5875C10.8042 3.97917 11 4.45 11 5V19C11 19.55 10.8042 20.0208 10.4125 20.4125C10.0208 20.8042 9.55 21 9 21H5ZM9 5H5V19H9V5Z"
      fill="white"
    />
    <path
      d="M19 5.00001H18C17.45 5.00001 17 5.45001 17 6.00001C17 6.55 17.45 7 18 7H19V8C19 8.55 19.45 9 20 9C20.55 9 21 8.55 21 8V7H22C22.55 7 23 6.55 23 6C23 5.45 22.55 5.00001 22 5.00001L21 5.00001V4.00001C21 3.45001 20.55 3.00001 20 3.00001C19.45 3.00001 19 3.45001 19 4.00001L19 5.00001Z"
      fill="white"
    />
  </svg>
);

export default class ProductCategories extends ProductCategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { activeStep, isLoading, isAddNewModalOpen, selectedCategory } =
      this.state;
    const category = this.props.navigation
      .getParam("category")
      ?.split("-")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    const steps = [category, "Export to PDF"];

    return (
      <>
        {isAddNewModalOpen && (
          <ModalCore
            title="Add new equipment"
            open={isAddNewModalOpen}
            handleClose={this.handleCloseAddModal}
          >
            <>
              <StyledSlider>
                <BaseSlider type="categoryModal">
                  {CATEGORIES.map((category) => (
                    <CategoryItem key={category.key}>
                      <CategoryImage
                        src={category.imageUrl}
                        alt={category.title}
                      />
                      <CategoryText>
                        <CategoryTitle>{category.title}</CategoryTitle>
                        <CategoryDescription>
                          {category.description}
                        </CategoryDescription>
                      </CategoryText>
                      <Button
                        variant={
                          selectedCategory === category.key
                            ? "secondary"
                            : "primary"
                        }
                        data-test-id="select-category-btn"
                        style={{ padding: "8px 12px", marginTop: "8px" }}
                        onClick={() => this.handleSelectCategory(category.key)}
                      >
                        {selectedCategory === category.key
                          ? "Selected"
                          : "Select"}
                      </Button>
                    </CategoryItem>
                  ))}
                </BaseSlider>
              </StyledSlider>

              <Divider />

              <ButtonContainer>
                <Button variant="secondary" onClick={this.handleCloseAddModal}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={this.handleAddNewEquipment}
                  disabled={!selectedCategory}
                >
                  Next
                </Button>
              </ButtonContainer>
            </>
          </ModalCore>
        )}
        <DashboardLayout>
          <Formik
            initialValues={{
              number_of_cows: "150",
              stall_system: "",
              stall_groups: "24",
              stall_layout: "",
              stall_spacing_in_inches: "12",
              length_of_beds: "12",
              post_spacing: "12",
              post_type: "",
              head_to_head_posts: "",
              curb_height: "12",
              end_wall_height: "12",
            }}
            onSubmit={this.handleMoveNext}
            data-test-id="product-categories-form"
          >
            {({ values, handleChange }) => (
              <Form>
                <ProductCategoriesContainer>
                  <PageHeader>
                    <Breadcrumbs>
                      <BreadcrumbLink label="Dashboard" href={"/"} />
                      <BreadcrumbLink label="Product Categories" />
                    </Breadcrumbs>
                    <Button
                      variant="primary"
                      data-test-id="add-new-equipment-btn"
                      onClick={() => this.setState({ isAddNewModalOpen: true })}
                    >
                      <AddEquipmentIconBlock>
                        <AddEquipmentIcon />
                      </AddEquipmentIconBlock>
                      <AddEquipmentText>Add new equipment</AddEquipmentText>
                    </Button>
                  </PageHeader>

                  <BaseStepper steps={steps} activeStep={activeStep} />

                  {activeStep === 0 ? (
                    <Accordion
                      style={{ borderRadius: "8px", boxShadow: "0 0 0 0" }}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div>
                          <StyledEquipment>{category}</StyledEquipment>
                          <Button variant="tertiary">Add another</Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ backgroundColor: "#fff" }}>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            backgroundColor: Colors.bgPrimary,
                            marginLeft: "-6px",
                            paddingBottom: "16px",
                          }}
                        >
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <HorizontalField>
                                <Label style={{ marginBottom: 0, flex: 1 }}>
                                  Enter the number of cows (Optional)
                                </Label>
                                <CustomCounter
                                  value={this.state.cowNumber}
                                  onChange={this.handleCowNumberChange}
                                />
                              </HorizontalField>
                            </FieldContainer>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <SelectField>
                                <Label style={{ marginBottom: 0, flex: 1 }}>
                                  Choose Stall System
                                </Label>
                                <CheckboxItemContainer>
                                  {STALL_SYSTEM.map((item) => (
                                    <CheckboxItem key={item.key}>
                                      <Checkbox
                                        name="stall_system"
                                        checked={
                                          item.key === values.stall_system
                                        }
                                        value={item.key}
                                        onChange={handleChange}
                                      />
                                      <CheckboxText>{item.title}</CheckboxText>
                                    </CheckboxItem>
                                  ))}
                                </CheckboxItemContainer>
                              </SelectField>
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <Label>
                                How many Stall groups do you want to configure?
                              </Label>
                              <Input
                                type="text"
                                name={"stall_groups"}
                                value={values.stall_groups}
                                onChange={handleChange}
                              />
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <SelectField>
                                <Label style={{ marginBottom: 0, flex: 1 }}>
                                  Stall Layout
                                </Label>
                                <CheckboxItemContainer>
                                  {STALL_LAYOUT.map((item) => (
                                    <CheckboxItem key={item.key}>
                                      <Checkbox
                                        name="stall_layout"
                                        checked={
                                          item.key === values.stall_layout
                                        }
                                        value={item.key}
                                        onChange={handleChange}
                                      />
                                      <CheckboxText>{item.title}</CheckboxText>
                                    </CheckboxItem>
                                  ))}
                                </CheckboxItemContainer>
                              </SelectField>
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} sm={6} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <Label>Stall spacing in inches</Label>
                              <Input
                                type="text"
                                name={"stall_spacing_in_inches"}
                                value={values.stall_spacing_in_inches}
                                onChange={handleChange}
                              />
                            </FieldContainer>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <Label>Length of Beds</Label>
                              <Input
                                type="text"
                                name={"length_of_beds"}
                                value={values.length_of_beds}
                                onChange={handleChange}
                              />
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <Label>Post Spacing</Label>
                              <Input
                                type="text"
                                name={"post_spacing"}
                                value={values.post_spacing}
                                onChange={handleChange}
                              />
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <SelectField>
                                <Label style={{ marginBottom: 0, flex: 1 }}>
                                  Post Type
                                </Label>
                                <CheckboxItemContainer>
                                  {POST_TYPE.map((item) => (
                                    <CheckboxItem key={item.key}>
                                      <Checkbox
                                        name="post_type"
                                        checked={item.key === values.post_type}
                                        value={item.key}
                                        onChange={handleChange}
                                      />
                                      <CheckboxText>{item.title}</CheckboxText>
                                    </CheckboxItem>
                                  ))}
                                </CheckboxItemContainer>
                              </SelectField>
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <SelectField>
                                <Label style={{ marginBottom: 0, flex: 1 }}>
                                  For Head-to-Head are the Posts Centered or
                                  Offset
                                </Label>
                                <CheckboxItemContainer>
                                  {HEAD_TO_HEAD.map((item) => (
                                    <CheckboxItem key={item.key}>
                                      <Checkbox
                                        name="head_to_head_posts"
                                        checked={
                                          item.key === values.head_to_head_posts
                                        }
                                        value={item.key}
                                        onChange={handleChange}
                                      />
                                      <CheckboxText>{item.title}</CheckboxText>
                                    </CheckboxItem>
                                  ))}
                                </CheckboxItemContainer>
                              </SelectField>
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} sm={6} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <Label>Curb height</Label>
                              <Input
                                type="text"
                                name={"curb_height"}
                                value={values.curb_height}
                                onChange={handleChange}
                              />
                            </FieldContainer>
                          </Grid>{" "}
                          <Grid item xs={12} sm={6} style={{ paddingLeft: 0 }}>
                            <FieldContainer>
                              <Label>
                                Height of End Wall on Free Stall runs
                              </Label>
                              <Input
                                type="text"
                                name={"end_wall_height"}
                                value={values.end_wall_height}
                                onChange={handleChange}
                              />
                            </FieldContainer>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <>
                      <PdfItemContainer>
                        <div>
                          <p style={{ color: Colors.textTertiary }}>
                            Custom equipment
                          </p>
                          <PdfName>Free Stall</PdfName>
                        </div>
                        <Button variant="secondary" style={{ width: "40%" }}>
                          Download PDF
                        </Button>
                      </PdfItemContainer>
                      <Accordion
                        style={{ borderRadius: "8px", boxShadow: "0 0 0 0" }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          Technical details
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>Technical details</p>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}

                  {activeStep !== steps.length - 1 && (
                    <ButtonContainer>
                      {activeStep !== 0 ? (
                        <Button
                          type="button"
                          variant="secondary"
                          style={{ flex: 1 }}
                          onClick={this.handleMoveBack}
                        >
                          Cancel
                        </Button>
                      ) : (
                        <div style={{ flex: 1 }}></div>
                      )}
                      <Button
                        variant="primary"
                        style={{ flex: 1 }}
                        type="submit"
                      >
                        {isLoading ? (
                          <Spinner height="20px" width="20px" />
                        ) : (
                          "Generate"
                        )}
                      </Button>
                    </ButtonContainer>
                  )}
                </ProductCategoriesContainer>
              </Form>
            )}
          </Formik>
        </DashboardLayout>
      </>
    );
  }
  // Customizable Area End
}
