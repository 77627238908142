import React from 'react';
import { Grid, Box, Divider } from '@mui/material';
import { styled } from '@mui/styles';
import { Colors } from '../../../blocks/utilities/src/Colors';
import {
  ContentPasteOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
  Inventory2Outlined,
  SellOutlined
} from '@mui/icons-material';
import {
  formatCurrency,
  getInventoryStatusClass,
  getInventoryStatusText
// @ts-ignore
} from './utils';
import { ModalCore } from '../ui/modal';
import { Button } from '../ui/button';
import { TInventoryItem } from '../../../blocks/catalogue/src/CatalogueController';

interface InventoryDetailProps {
  item: TInventoryItem;
  isOpen: boolean;
  onClose: () => void;
}

const StyledBadge = styled(Box)({
  backgroundColor: Colors.bgPrimary,
  padding: '4px 8px',
  borderRadius: '8px',
  fontSize: '12px',
  color: 'black',
  display: 'inline'
});

const DialogTitle = styled('h3')({
  fontWeight: '600',
  fontSize: '18px',
  marginTop: '8px',
  color: Colors.btnPrimary
});

const DialogDescription = styled('div')({
  fontSize: '16px',
  color: Colors.gray,
  marginTop: '8px'
});

const Section = styled('div')({
  margin: '20px 0'
});

const SectionBox = styled('div')({
  backgroundColor: Colors.bgPrimary,
  backdropFilter: 'blur(10px)',
  padding: '10px',
  borderRadius: '8px'
});

const SectionTitle = styled('h4')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontWeight: '600',
  fontSize: '14px',
  color: Colors.gray,
  marginBottom: '8px'
});

const SectionItemTitle = styled('p')({
  fontSize: '12px',
  color: Colors.gray
});

const ItemNumber = styled('div')({
  fontWeight: '600',
  marginTop: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
});

const ItemValue = styled('div')({
  fontWeight: '600',
  marginTop: '4px',
  display: 'flex',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

const ClipboardContainer = styled('div')({
  fontWeight: '600',
  marginTop: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const ModalBottom = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem'
});

export const InventoryDetail = ({
                                  item,
                                  isOpen,
                                  onClose
                                }: InventoryDetailProps) => {
  const { attributes } = item;

  const copyToClipboard = (text: string, label: string) => {
    navigator.clipboard.writeText(text);
  };

  const statusStyle = getInventoryStatusClass(+attributes.inventory);
  const statusText = getInventoryStatusText(attributes.inventory);

  return (
    <ModalCore open={isOpen} handleClose={onClose}>
      <div>
        <Box>
          <div style={{ display: 'flex', gap: '4px' }}>
            <StyledBadge>{attributes.no}</StyledBadge>
            <StyledBadge>{attributes.type}</StyledBadge>
            <StyledBadge style={statusStyle}>{statusText}</StyledBadge>
          </div>
          <DialogTitle className="text-xl font-semibold mt-2 text-balance">
            {attributes.description}
          </DialogTitle>
          <DialogDescription className="text-muted-foreground">
            {attributes.global_dimension_2_code} •{' '}
            {attributes.item_category_code}
          </DialogDescription>
        </Box>

        <div className="space-y-6 p-1">
          <Section>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <SectionTitle>
                  <ContentPasteOutlined /> Item Details
                </SectionTitle>

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Item Number</SectionItemTitle>
                      <ClipboardContainer>
                        {attributes.no}
                        <div
                          onClick={() =>
                            copyToClipboard(attributes.no, 'Item number')
                          }
                        >
                          <ContentPasteOutlined />
                        </div>
                      </ClipboardContainer>
                    </SectionBox>
                  </Grid>

                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Category</SectionItemTitle>
                      <ItemValue>{attributes.item_category_code}</ItemValue>
                    </SectionBox>
                  </Grid>

                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Unit of Measure</SectionItemTitle>
                      <ItemValue>{attributes.base_unit_of_measure}</ItemValue>
                    </SectionBox>
                  </Grid>

                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Department</SectionItemTitle>
                      <ItemValue>
                        {attributes.global_dimension_2_code}
                      </ItemValue>
                    </SectionBox>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6}>
                <SectionTitle>
                  <SellOutlined /> Pricing Information
                </SectionTitle>

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Unit Price</SectionItemTitle>
                      <ItemValue style={{ color: Colors.btnPrimary }}>
                        {formatCurrency(attributes.unit_price)}
                      </ItemValue>
                    </SectionBox>
                  </Grid>

                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Standard Cost</SectionItemTitle>
                      <ItemValue>
                        {formatCurrency(attributes.standard_cost)}
                      </ItemValue>
                    </SectionBox>
                  </Grid>

                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Avg Material Cost</SectionItemTitle>
                      <ItemValue>
                        {formatCurrency(attributes.avg_material_cost)}
                      </ItemValue>
                    </SectionBox>
                  </Grid>

                  <Grid item md={6}>
                    <SectionBox>
                      <SectionItemTitle>Last Direct Cost</SectionItemTitle>
                      <ItemValue>
                        {formatCurrency(attributes.last_direct_cost)}
                      </ItemValue>
                    </SectionBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Section>

          <Divider />

          <Section>
            <SectionTitle>
              <Inventory2Outlined /> Inventory Status
            </SectionTitle>

            <Grid container spacing={3}>
              <Grid item sm={2} md={3}>
                <SectionBox>
                  <SectionItemTitle>Current Inventory</SectionItemTitle>
                  <ItemNumber>
                    <span>{attributes.inventory}</span>
                    <SectionItemTitle>units</SectionItemTitle>
                  </ItemNumber>
                </SectionBox>
              </Grid>

              <Grid item sm={2} md={3}>
                <SectionBox>
                  <SectionItemTitle>Available</SectionItemTitle>
                  <ItemNumber>
                    <span>
                      {attributes.available_inventory}
                    </span>
                    <SectionItemTitle>units</SectionItemTitle>
                  </ItemNumber>
                </SectionBox>
              </Grid>

              <Grid item sm={2} md={3}>
                <SectionBox>
                  <SectionItemTitle>Status</SectionItemTitle>
                  <div style={{
                    ...statusStyle,
                    padding: '2px 4px',
                    fontSize: '12px',
                    marginTop: '4px',
                    borderRadius: '8px',
                    width: 'fit-content'
                  }}>
                      {statusText}
                  </div>
                </SectionBox>
              </Grid>

              <Grid item sm={2} md={3}>
                <SectionBox>
                  <SectionItemTitle>Net Weight</SectionItemTitle>
                  <ItemNumber>
                    <span>{attributes.net_weight || '0'}</span>
                    <SectionItemTitle>kg</SectionItemTitle>
                  </ItemNumber>
                </SectionBox>
              </Grid>
            </Grid>
          </Section>
          <Divider />

          <Section>
            <SectionTitle>
              <BarChartOutlined /> Additional Information
            </SectionTitle>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <SectionBox>
                  <SectionItemTitle>Search Description</SectionItemTitle>
                  <ItemValue>{attributes.search_description}</ItemValue>
                </SectionBox>
              </Grid>

              <Grid item md={6}>
                <SectionBox>
                  <SectionItemTitle>Product Group</SectionItemTitle>
                  <ItemValue>{attributes.product_group_code}</ItemValue>
                </SectionBox>
              </Grid>
            </Grid>
          </Section>
        </div>

        <ModalBottom>
          <SectionTitle>
            <ShoppingCartOutlined />
            Total Value:{' '}
            {formatCurrency(
              (
                parseFloat(attributes.inventory) *
                parseFloat(attributes.unit_price)
              ).toString()
            )}
          </SectionTitle>

          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalBottom>
      </div>
    </ModalCore>
  );
};
