import React from "react";
// Customizable Area Start
// Customizable Area End
import CustomizeBarnController, { Props } from "./CustomizeBarnController";
import { styled } from "@mui/styles";

import { Colors } from "../../utilities/src/Colors";
import { Button } from "../../../components/src/ui/button";
import { BaseStepper } from "../../../components/src/ui/stepper";
import {
  Breadcrumbs,
  BreadcrumbLink,
} from "../../../components/src/ui/breadcrumbs";
import DashboardLayout from "../../../components/src/dashboard/layout";

import { Form, Formik } from "formik";
import FirstStepForm from "../../../components/src/quote/FirstStepForm";
import ThirdStepForm from "../../../components/src/quote/ThirdStepForm";
import DesignLayout from "../../../components/src/quote/DesignLayout";
import PdfExporter from "../../../components/src/quote/PdfExporter";

const CustomizeBarnContainer = styled("main")({
  padding: "30px 48px",
  minHeight: "100vh",
  backgroundColor: Colors.bgPrimary,
  "@media (max-width: 768px)": {
    padding: "30px 20px",
  }
});

const ButtonContainer = styled("div")({
  display: "flex",
  gap: "80px",
  width: "75%",
  margin: "24px auto",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "16px",
    width: "100%"
  }
});

const PageHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "32px",
  "@media (max-width: 768px)": {
    margin: "40px 0 24px"
  }
});

export default class CustomizeBarn extends CustomizeBarnController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderStepForm = (activeStep: number, values: any, handleChange: any) => {
    let stepForm;
    switch (activeStep) {
      case 0:
        stepForm = (
          <FirstStepForm
            values={values}
            handleChange={handleChange}
            cowNumber={this.state.cowNumber}
            handleCowNumberChange={this.handleCowNumberChange}
          />
        );
        break;
      case 1:
      case 3:
        stepForm = <DesignLayout />;
        break;
      case 2:
        stepForm = (
          <ThirdStepForm
            values={values}
            handleChange={handleChange}
            cowNumber={this.state.cowNumber}
            handleCowNumberChange={this.handleCowNumberChange}
          />
        );
        break;
      case 4:
        stepForm = <PdfExporter />;
        break;
      default:
        stepForm = null;
    }
    return stepForm;
  };

  render() {
    // Customizable Area Start
    const { activeStep } = this.state;
    const category = this.props.navigation
      .getParam("option")
      ?.split("-")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    const steps = [
      category,
      "Design Layout",
      "Initial Design Customization",
      "Full Barn Customization",
      "Export",
    ];

    return (
      <DashboardLayout>
        <CustomizeBarnContainer>
          <PageHeader>
            <Breadcrumbs>
              <BreadcrumbLink label="Dashboard" href={"/"} />
              <BreadcrumbLink label={category} />
            </Breadcrumbs>
          </PageHeader>

          <BaseStepper steps={steps} activeStep={activeStep} />

          <Formik
            initialValues={{
              internal_travel_lane: "",
            }}
            onSubmit={() => {}}
            data-test-id="customize-barn-form"
          >
            {({ values, handleChange, submitForm }) => (
              <Form>
                <>
                  {this.renderStepForm(activeStep, values, handleChange)}
                  {activeStep !== steps.length - 1 && (
                    <ButtonContainer>
                      {activeStep !== 0 ? (
                        <Button
                          variant="secondary"
                          style={{ flex: 1 }}
                          onClick={this.handleMoveBack}
                        >
                          Back
                        </Button>
                      ): <div style={{ flex: 1 }}></div>}
                      <Button
                        variant="primary"
                        style={{ flex: 1 }}
                        onClick={() => this.handleMoveNext(activeStep)}
                      >
                        Next
                      </Button>
                    </ButtonContainer>
                  )}
                </>
              </Form>
            )}
          </Formik>
        </CustomizeBarnContainer>
      </DashboardLayout>
    );
    // Customizable Area End
  }
}
