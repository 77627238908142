import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest } from "../../../components/src/utils/api";
import { pdfFile } from "./assets";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  cowNumber: number;
  // Customizable Area End
}
interface SS {}

export default class CustomizeBarnController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createDesignRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeStep: 0,
      cowNumber: 150,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createDesignRequestId) {
          this.setState({ activeStep: this.state.activeStep + 1 });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // istanbul ignore next
  handleMoveNext = async(activeStep: number) => {
    if (activeStep === 0) {
      // call create design API
      const formData = new FormData();
      formData.append("barn_design[title]", "Hung barn design");
      formData.append("barn_design[survey_values]", "Some survey values");

      // Read the PDF file from the repository
      const response = await fetch(pdfFile);
      const blob = await response.blob();
      const file = new File([blob], "dummy.pdf", { type: "application/pdf" });
      formData.append("barn_design[pdf_file]", file);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createDesignRequestId = requestMessage.messageId;

      createRequest({
        isFormDataRequest: true,
        requestMsg: requestMessage,
        endPoint: "account_block/barn_designs",
        method: "POST",
        body: formData,
      });
    } else {
      this.setState({activeStep: this.state.activeStep + 1});
    }
  }

  handleMoveBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleCowNumberChange = (newValue: number) => {
    this.setState({ cowNumber: newValue });
  };
  // Customizable Area End
}
