import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { visuallyHidden } from "@mui/utils";
import { Badge } from "../ui/badge";
import { Order, HeadCell, EnhancedTableProps, ExistingQuotes } from "./types";
import { Colors } from "../../../blocks/utilities/src/Colors";
import ProjectDropdownMenu from "./ProjectDropdownMenu";
// @ts-ignore
import { getComparator } from "./utils";
import { useState } from "react";

const headCells: readonly HeadCell[] = [
  {
    id: "barn_type",
    numeric: false,
    disablePadding: true,
    label: "Project Type",
  },
  {
    id: "client",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Cost Est",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof ExistingQuotes) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              color: Colors.btnPrimary,
              fontWeight: 700,
              paddingLeft: "24px",
              fontFamily: "Inter",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              style={{ color: Colors.btnPrimary, fontWeight: 700 }}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          style={{ color: Colors.btnPrimary, fontWeight: 700 }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

const StyledTableCell = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    style={{
      paddingLeft: "24px",
      fontFamily: "Inter",
      color: Colors.textPrimary,
      flex: 1,
    }}
  >
    {children}
  </TableCell>
);

export default function ProjectTable({ data }: { data: ExistingQuotes[] }) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof ExistingQuotes>("created_at");
  const [page, setPage] = useState(1);
  const rowsPerPage = 8;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ExistingQuotes
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const visibleRows = React.useMemo(() => {
    const sortedData = data.length
      ? [...data].sort(getComparator(order, orderBy))
      : [];
    const startIndex = (page - 1) * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [order, orderBy, data, page]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {visibleRows.map((row: ExistingQuotes) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <StyledTableCell>{row.barn_type}</StyledTableCell>
                    <StyledTableCell>{row.client}</StyledTableCell>
                    <StyledTableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(+row.amount)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {new Date(row.created_at).toLocaleDateString("en-US")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge label={row.status} variant={row.status} />
                    </StyledTableCell>
                    <TableCell style={{ width: "100px" }}>
                      <ProjectDropdownMenu details={row} />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={5} style={{ justifyContent: "center" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={Math.ceil(data.length / rowsPerPage)}
                      color="primary"
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <p style={{ fontSize: "14px", color: Colors.textTertiary }}>
                    {`${(page - 1) * rowsPerPage + 1}-${Math.min(
                      page * rowsPerPage,
                      data.length
                    )} of ${data.length} results`}
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
