// Customizable Area Start
import React from "react";
import ChooseClientModalController, {
  Props,
  // @ts-ignore
} from "./ChooseClientModalController";
import { ModalCore } from "../ui/modal";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { styled } from "@mui/styles";
// @ts-ignore
import { ChooseClientDropdown } from "./dropdown/ChooseClientDropdown";
import AddClientModal from "./AddClientModal";

// Customizable Area End

const StyledHorizontalLines = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E5E5",
  margin: "24px 0",
});

const StyledButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "24px",
});


export default class ChooseClientModal extends ChooseClientModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { open, closeModal, category, navigation } = this.props;
    const { addModalOpen } = this.state
    return (
      <ModalCore open={open} handleClose={closeModal} title={category}>
        <>
          <StyledHorizontalLines />
          <Label>Choose client or create new</Label>

          <ChooseClientDropdown onCreateNewClient={this.openModal} />
          <AddClientModal open={addModalOpen} closeModal={this.closeModal} navigation={navigation} />

          <StyledHorizontalLines />

          <StyledButtonContainer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.redirectToCustomization}>
              Next
            </Button>
          </StyledButtonContainer>
        </>
      </ModalCore>
    );
  }
}
