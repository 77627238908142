import React from "react";
import { makeStyles } from '@mui/styles';
import { Colors } from "../../../../blocks/utilities/src/Colors";

const useStyles = makeStyles({
  button: {
    fontFamily: 'Inter',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    padding: '16px 20px',
    display: 'flex',
    gap: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      padding: '16px',
      fontSize: '14px',
    },
    "&:disabled": {
      backgroundColor: "#D3D3D3",
      color: "#A9A9A9",
      cursor: "not-allowed"
    },
  },
  primary: {
    backgroundColor: Colors.btnPrimary,
    color: Colors.white
  },
  secondary: {
    backgroundColor: Colors.btnSecondary,
    color: Colors.btnPrimary
  },
  tertiary: {
    display: 'inline-block',
    border: 0,
    outline: 'none',
    backgroundColor: 'transparent',
    color: Colors.btnPrimary,
    paddingTop: 0,
  },
  warning: {
    display: "inline-block",
    border: 0,
    outline: "none",
    color: Colors.error,
    backgroundColor: "transparent",
    paddingTop: 0,
  }
})

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "primary" | "secondary" | "tertiary" | "warning",
  prependIcon?: React.ReactNode,
  children: React.ReactNode
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({variant = "primary", prependIcon, children, ...props}, ref) => {
  const styles = useStyles();
  return (
    <button className={`${styles.button} ${styles[variant]}`} ref={ref} {...props}>
      {prependIcon && prependIcon}
      {children}
    </button>
  );
})

export {Button};

Button.displayName = 'Button';