import React, { useState } from 'react';
import { styled } from '@mui/styles';
import { Box } from '@mui/material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TInventoryItem } from '../../../blocks/catalogue/src/CatalogueController';
import { Button } from '../ui/button';
import { Colors } from '../../../blocks/utilities/src/Colors';
// @ts-ignore
import { InventoryDetail } from './InventoryDetail';

const GlassCard = styled(Box)({
  background: Colors.white,
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  padding: '20px',
  animation: 'fadeIn 0.3s ease-in-out',
  transition: 'opacity 0.3s ease-in-out'
});

const StatusBadge = styled(Box)({
  backgroundColor: Colors.bgPrimary,
  padding: '4px 8px',
  borderRadius: '8px',
  fontSize: '12px',
  color: 'black'
});

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.lightGray,
  width: '48px',
  height: '48px',
  borderRadius: '8px'
});

const ItemDescription = styled('h3')({
  fontWeight: '600',
  fontSize: '16px',
  color: Colors.btnPrimary
});

const SecondaryText = styled('span')({
  fontSize: '14px',
  color: Colors.textSecondary
});

const InventoryItemContainer = styled(GlassCard)({});

interface InventoryItemProps {
  item: TInventoryItem;
}

export const InventoryItem = ({ item }: InventoryItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);

  const {
    description,
    no,
    global_dimension_2_code,
    item_category_code,
    unit_price,
    base_unit_of_measure,
    inventory
  } = item.attributes;

  return (
    <InventoryItemContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={2}
        alignItems="center"
      >
        <IconWrapper>
          <Inventory2OutlinedIcon style={{ color: Colors.btnPrimary }} />
        </IconWrapper>

        <Box flex={1} minWidth={0}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <ItemDescription>{description}</ItemDescription>
                <StatusBadge>{no}</StatusBadge>
              </Box>
              <Box display="flex" alignItems="center" gap={1} mt={1}>
                <SecondaryText>{global_dimension_2_code}</SecondaryText>
                <Box
                  sx={{
                    width: '4px',
                    height: '4px',
                    bgcolor: 'text.secondary',
                    borderRadius: '50%'
                  }}
                />
                <SecondaryText>{item_category_code}</SecondaryText>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" gap={3}>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <ItemDescription>{`$${unit_price}`}</ItemDescription>
                <SecondaryText>Per {base_unit_of_measure}</SecondaryText>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <StatusBadge>{no}</StatusBadge>
                <p style={{ fontSize: '12px', color: Colors.textSecondary, marginTop: '4px' }}>
                  <b>{inventory} </b> units available
                </p>
              </Box>
            </Box>
          </Box>
        </Box>

        <Button
          variant="tertiary"
          style={{
            minWidth: '40px',
            marginLeft: 'auto',
            transition: 'all 0.3s ease-in-out',
            padding: 0
          }}
          onClick={() => setIsDetailDialogOpen(true)}
        >
          {isHovered ? <ArrowForwardOutlinedIcon /> : <InfoOutlinedIcon />}
        </Button>
      </Box>

      <InventoryDetail
        item={item}
        isOpen={isDetailDialogOpen}
        onClose={() => setIsDetailDialogOpen(false)}
      />
    </InventoryItemContainer>
  );
};
