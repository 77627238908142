import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableRow,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";
import {finalDesign, successCheck} from "../../../blocks/dashboard/src/assets";
import { Button } from "../ui/button";
import { SuccessModal } from "../ui/modal/success-modal";
import { PreviewDesignHeader } from "./style";

const StyledPrice = styled("span")({
  fontWeight: 700,
  fontSize: "18px",
  color: Colors.textPrimary,
  marginRight: "8px",
  "@media (max-width: 768px)": {
    fontSize: "16px",
  }
});

const StyledAvatar = styled("div")({
  width: "36px",
  height: "36px",
  borderRadius: "50%",
  backgroundColor: Colors.bgPrimary,
  marginRight: "16px",
  flexShrink: 0,
});

const StyledItemTitle = styled("h3")({
  fontSize: "16px",
  fontWeight: 400,
  color: Colors.textPrimary,
});

const StyledTableHeader = styled("tr")({
  "& > td": {
    fontWeight: 700,
    color: Colors.textPrimary,
    padding: "8px",
  },
});

const StyledTableCell = styled("td")({
  padding: "8px",
  borderBottom: `1px solid ${Colors.lightGray}`,
});

const StyledTotalBlock = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  margin: "16px",
  width: "30%",
  "@media (max-width: 768px)": {
    width: "100%",
  }
});

const StyledGrandTotal = styled("span")({
  fontSize: "18px",
  fontWeight: 700,
  color: Colors.btnPrimary,
});

const StyledCostBreakdown = styled("div")({
  fontWeight: 700,
  color: Colors.textPrimary,
  marginLeft: "16px",
});

const StyledPreviewDesign = styled("div")({
  padding: "14px",
  backgroundColor: Colors.white,
  borderRadius: "8px",
  marginBottom: "24px",
});

const PreviewDesignInfoBlock = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "16px",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "1rem",
  }
});

const EstimateText = styled("p")({
  color: Colors.textTertiary,
  fontSize: "14px",
  marginBottom: "16px",
});

const EstimateNumber = styled("span")({
  color: Colors.textTertiary,
  fontSize: "32px",
  fontWeight: 700,
});

const PreviewImage = styled("img")({
  width: "100%",
  height: "auto",
});

const BreakDownTable = styled("table")({
  width: "100%",
  backgroundColor: "#EEF2FF",
  borderRadius: "12px",
  padding: "24px",
  "@media (max-width: 768px)": {
    padding: "16px",
  }
});

const costBreakdownData = [
  {
    title: "Ventilation",
    totalCost: "$1,100.00",
    details: [],
  },
  {
    title: "Free Stalls",
    totalCost: "$1,200.00",
    details: [
      {
        name: "Neck Rail",
        quantity: 30,
        unitPrice: "$30.00",
        total: "$900.00",
      },
      {
        name: "Brisket Pipe",
        quantity: 30,
        unitPrice: "$10.00",
        total: "$300.00",
      },
    ],
  },
  {
    title: "Gates/Panels",
    totalCost: "$1,800.00",
    details: [],
  },
  {
    title: "Headlocks/Slant bars/Feed Rail",
    totalCost: "$1,800.00",
    details: [],
  },
  {
    title: "Cow Mats",
    totalCost: "$1,800.00",
    details: [],
  },
  {
    title: "Sprinklers",
    totalCost: "$1,800.00",
    details: [],
  },
  {
    title: "Curtains",
    totalCost: "$1,800.00",
    details: [],
  },
  {
    title: "FS Curb Detail 1",
    totalCost: "$1,800.00",
    details: [],
  },
  {
    title: "FS Curb Detail 2",
    totalCost: "$1,800.00",
    details: [],
  },
];

export default function PdfExporter() {
  const [isPriceExpanded, setIsPriceExpanded] = useState<number | boolean>(
    false
  );
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  useEffect(() => {
    setIsSuccessModalOpen(true);
  }, []);

  return (
    <div>
      <StyledPreviewDesign>
        <PreviewDesignHeader>Your Design</PreviewDesignHeader>
        <PreviewImage src={finalDesign} alt="final-design" />
        <PreviewDesignInfoBlock>
          <div style={{ flex: 1, width: "100%" }}>
            <EstimateText>
              Estimated number of cows fits current design
            </EstimateText>
            <EstimateNumber>150</EstimateNumber>
          </div>
          <div style={{ flex: 1, width: "100%" }}>
            <Button variant="secondary" style={{ width: "100%" }}>
              Download PDF
            </Button>
          </div>
        </PreviewDesignInfoBlock>
      </StyledPreviewDesign>
      <Accordion style={{ borderRadius: "8px", boxShadow: "0 0 0 0" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`cost-breakdown`}
          id={`cost-breakdown`}
        >
          <StyledCostBreakdown>Cost Breakdown</StyledCostBreakdown>
        </AccordionSummary>
        <AccordionDetails>
          {costBreakdownData.map((item, index) => (
            <Accordion
              key={index}
              style={{ boxShadow: "none" }}
              expanded={isPriceExpanded === index}
              onChange={(_, expanded: boolean) =>
                setIsPriceExpanded(expanded ? index : false)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item style={{flex: 1}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <StyledAvatar />
                      <StyledItemTitle>{item.title}</StyledItemTitle>
                    </div>
                  </Grid>
                  {isPriceExpanded !== index ? (
                    <Grid item>
                      <StyledPrice>{item.totalCost}</StyledPrice>
                    </Grid>
                  ) : null}
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "8px", overflow: "auto" }}>
                {item.details.length > 0 ? (
                  <BreakDownTable>
                    <thead>
                      <StyledTableHeader>
                        <td>Item</td>
                        <td align="right">Quantity</td>
                        <td align="right">Unit Price</td>
                        <td align="right">Total</td>
                      </StyledTableHeader>
                    </thead>
                    <tbody>
                      {item.details.map((detail, idx) => (
                        <TableRow key={idx}>
                          <StyledTableCell>{detail.name}</StyledTableCell>
                          <StyledTableCell align="right">
                            {detail.quantity}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {detail.unitPrice}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {detail.total}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <StyledTableCell
                          style={{ fontWeight: 700 }}
                          align="right"
                        >
                          Total
                        </StyledTableCell>
                        <StyledTableCell align="right">$1200</StyledTableCell>
                      </tr>
                    </tbody>
                  </BreakDownTable>
                ) : (
                  <Typography>No details available.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginTop: "16px" }}
          >
            <StyledTotalBlock>
              <StyledGrandTotal>Grand Total</StyledGrandTotal>
              <StyledPrice>$15,500.00</StyledPrice>
            </StyledTotalBlock>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <SuccessModal
        open={isSuccessModalOpen}
        title={"🎉 Order Successfully Created!"}
        illustrationImage={successCheck}
        description={"Your order has been successfully placed! We'll send you an update when it's on its way. 🚀"}
        handleClick={() => setIsSuccessModalOpen(false)}
      />
    </div>
  );
}
