import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import { Colors } from "../../../../blocks/utilities/src/Colors";

const useStyles = makeStyles({
  rootStep: {
    padding: "18px 0",
    margin: "12px 0",
    overflow: "auto",
    "& .MuiStep-root": {
      flex: 1,
    },
    "& .MuiStepConnector-horizontal": {
      display: "none",
    },
    "& .MuiStepLabel-label": {
      fontWeight: "bold",
      color: Colors.textTertiary,
      fontFamily: "Inter",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: Colors.textPrimary,
    },
    "& .MuiStepIcon-text": {
      fontWeight: 700,
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: Colors.success,
    },
    "& .MuiSvgIcon-root": {
      width: "28px",
      height: "28px",
      color: Colors.textTertiary,
    },
    "& .MuiSvgIcon-root.Mui-active": {
      width: "28px",
      height: "28px",
      color: Colors.textPrimary,
    },
  },
});

interface BaseStepperProps {
  steps: string[];
  activeStep: number;
}

export const BaseStepper = ({ steps, activeStep }: BaseStepperProps) => {
  const styles = useStyles();

  return (
    <Box sx={{ width: "100%", marginBottom: "1rem" }}>
      <Stepper activeStep={activeStep} className={styles.rootStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
