import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Popover from "@mui/material/Popover";
import {
  AnalyticsIcon,
  CatalogIcon,
  DashboardIcon,
  NotificationsIcon,
  // @ts-ignore
} from "./SidebarIcons";
import { makeStyles } from "@mui/styles";
import { Menu, Logout } from "@mui/icons-material";
import { Colors } from "../../../blocks/utilities/src/Colors";

const useStyles = makeStyles({
  drawer: {
    "& .MuiDrawer-paper": {
      width: "200px",
      padding: "20px",
      boxSizing: "border-box",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  sidebarContainer: {
    padding: "20px",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "100vh",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  sidebarLinks: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    listStyle: "none",
    padding: 0,
  },
  sidebarItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    gap: "4px",
    cursor: "pointer",
    "@media(max-width: 768px)": {
      flexDirection: "row",
    },
  },
  avatar: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: Colors.gray,
    border: `1px solid ${Colors.gray}`,
  },
  hamburger: {
    position: "absolute",
    top: "30px",
    left: "20px",
    "@media(min-width: 768px)": {
      display: "none",
    },
  },
  logoutBlock: {
    display: "flex",
    gap: "8px",
    padding: "16px",
    cursor: "pointer",
  },
});

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const sidebarLinks = [
    {
      key: "dashboard",
      name: "Dashboard",
      href: "/",
      Icon: DashboardIcon,
    },
    {
      key: "analytics",
      name: "Analytics",
      href: "/analytics",
      Icon: AnalyticsIcon,
    },
    {
      key: "catalog",
      name: "Catalog",
      href: "/catalog",
      Icon: CatalogIcon,
    },
    {
      key: "notifications",
      name: "Notifications",
      href: "/notifications",
      Icon: NotificationsIcon,
    },
  ];

  const isActive = (href: string) => location.pathname === href;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAvatarClick = () => {
    navigate("/profile");
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
     ["token", "userId"].forEach((item) => {
       localStorage.removeItem(item);
       sessionStorage.removeItem(item);
     });
    navigate("/login");
  };

  return (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      {/* Sidebar */}
      <div className={styles.sidebarContainer}>
        {/* mock logo */}
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 12.4182C0.143093 12.2956 0.292999 12.1798 0.429278 12.0436C4.2996 8.18119 8.16991 4.31879 12.0266 0.442779C12.34 0.129428 12.6467 0 13.0896 0C17.9956 0.013624 22.9017 0.00681198 27.8077 0.00681198C27.9848 0.00681198 28.1552 0.00681198 28.455 0.00681198C28.2778 0.143052 28.1961 0.231607 28.0939 0.286103C26.4313 1.15804 24.9663 2.28882 23.6444 3.61716C17.1098 10.1635 10.5684 16.6962 4.02704 23.2357C3.87713 23.3855 3.77492 23.5831 3.6182 23.7942C1.76481 21.5054 0.660952 19.0463 0.231674 16.2874C0.143093 15.6948 0.0749533 15.1022 0 14.5095C0 13.8147 0 13.1131 0 12.4182Z"
            fill="#E5EFF5"
          />
          <path
            d="M0 21.4922C0.115837 21.6421 0.245302 21.7851 0.333883 21.9554C1.19244 23.5767 2.2963 25.014 3.59095 26.3083C10.1732 32.875 16.7418 39.4486 23.3173 46.0221C23.4536 46.1584 23.583 46.2946 23.733 46.4581C21.5389 48.236 19.079 49.3396 16.3262 49.7619C15.6993 49.8573 15.0656 49.9186 14.4319 50.0003H12.4559C12.3877 49.9118 12.3264 49.8164 12.2446 49.7346C8.25168 45.7428 4.25871 41.751 0.265744 37.7592C0.183976 37.6774 0.0885812 37.6229 0 37.548C0 32.1938 0 26.8396 0 21.4922Z"
            fill="#A666FF"
          />
          <path
            d="M21.532 49.9925C21.6819 49.8767 21.825 49.7473 21.9953 49.6587C23.617 48.8004 25.0548 47.6969 26.3563 46.3958C32.8908 39.8563 39.439 33.3168 45.9736 26.7773C46.1371 26.6138 46.2734 26.4231 46.4506 26.2051C48.0178 28.1056 49.108 30.156 49.5509 32.4653C49.8439 34.0116 49.8848 35.6124 49.987 37.1928C50.0006 37.4244 49.7894 37.7241 49.6054 37.9081C45.7351 41.7977 41.8511 45.6737 37.974 49.5497C37.8377 49.686 37.7219 49.8358 37.5993 49.9789H21.532V49.9925Z"
            fill="#C399FF"
          />
          <path
            d="M0 37.541C0.0885812 37.6091 0.183976 37.6704 0.265744 37.7522C4.25871 41.744 8.25168 45.7358 12.2446 49.7276C12.3264 49.8094 12.3877 49.9048 12.4559 49.9933H0C0 45.8448 0 41.6963 0 37.541Z"
            fill="white"
          />
          <path
            d="M49.9394 28.4119C49.8303 28.2143 49.7622 28.1054 49.7009 27.9895C48.8628 26.4024 47.793 24.9855 46.5188 23.7184C39.9501 17.1585 33.3815 10.5918 26.8128 4.03182C26.6493 3.86833 26.4517 3.7389 26.2405 3.56861C28.2983 1.87923 30.5196 0.775694 33.0272 0.34654C34.39 0.10812 35.8004 0.101308 37.1905 0.0263766C37.4017 0.0127527 37.6811 0.14218 37.8378 0.298856C41.8103 4.2498 45.7692 8.20756 49.7281 12.1789C49.8712 12.322 49.9871 12.574 49.9939 12.7784C50.0075 17.9351 49.9939 23.0849 49.9939 28.2416C49.9939 28.262 49.9802 28.2757 49.9326 28.4187L49.9394 28.4119Z"
            fill="#CCDEEA"
          />
          <path
            d="M15.0517 27.4827C15.2673 31.6786 19.0002 33.1534 25.2802 33.1534C30.7988 33.1534 34.4643 31.9694 34.4643 27.8843C34.4643 23.7991 30.7516 23.3075 25.0646 22.8644C20.8532 22.5043 20.112 22.1928 20.112 21.2165C20.112 20.344 20.8532 19.7901 24.1482 19.7901C27.6251 19.7901 29.121 20.3233 29.121 21.7288H33.8107C33.6827 18.1838 30.253 16.3281 24.2762 16.3281C18.9126 16.3281 15.4155 17.8237 15.4155 21.1472C15.4155 24.6715 18.1916 25.9663 24.4716 26.4164C29.0738 26.728 29.7678 26.9288 29.7678 28.092C29.7678 29.096 28.8582 29.6984 25.1185 29.6984C21.0352 29.6984 19.7549 28.8259 19.7549 27.4896H15.0449L15.0517 27.4827Z"
            fill="#400099"
          />
        </svg>

        <ul className={styles.sidebarLinks}>
          {sidebarLinks.map(({ key, name, href, Icon }) => (
            <li key={key} className={styles.sidebarItem}>
              <div
                onClick={() => navigate(href)}
                className={styles.sidebarItem}
              >
                <Icon
                  style={{
                    fill: isActive(href) ? Colors.btnPrimary : Colors.gray,
                  }}
                />
                <span
                  style={{
                    fontSize: "12px",
                    color: isActive(href) ? Colors.btnPrimary : Colors.gray,
                    fontWeight: 600,
                  }}
                >
                  {name}
                </span>
              </div>
            </li>
          ))}
        </ul>

        {/* mock avatar */}
        <div className={styles.avatar} onClick={handleAvatarClick} />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div onClick={handleLogout} className={styles.logoutBlock}>
            <Logout />
            <span>Log out</span>
          </div>
        </Popover>
      </div>

      <div className={styles.hamburger} onClick={() => setSidebarOpen(true)}>
        <Menu />
      </div>

      <Drawer
        anchor="left"
        open={sidebarOpen}
        className={styles.drawer}
        onClose={() => setSidebarOpen(false)}
      >
        <ul className={styles.sidebarLinks}>
          {sidebarLinks.map(({ key, name, href, Icon }) => (
            <li key={key} className={styles.sidebarItem}>
              <div
                onClick={() => navigate(href)}
                className={styles.sidebarItem}
              >
                <Icon
                  style={{
                    fill: isActive(href) ? Colors.btnPrimary : Colors.gray,
                  }}
                />
                <span
                  style={{
                    fontSize: "12px",
                    color: isActive(href) ? Colors.btnPrimary : Colors.gray,
                    fontWeight: 600,
                  }}
                >
                  {name}
                </span>
              </div>
            </li>
          ))}
        </ul>
        {/* mock avatar */}
        <div className={styles.avatar} onClick={handleAvatarClick} />
      </Drawer>

      {/* Main Content */}
      <div style={{ flex: 1, overflowY: "scroll" }}>{children}</div>
    </div>
  );
}
