import { styled } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";


export const Step1Container = styled("div")({
  maxWidth: "75%",
  margin: "0 auto",
  "@media(max-width: 768px)": {
    maxWidth: "100%"
  }
});

export const FieldContainer = styled("div")({
  backgroundColor: Colors.white,
  borderRadius: "8px",
  padding: "24px",
  "@media(max-width: 768px)": {
    padding: "16px"
  }
});

export const HorizontalField = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
});

export const SelectField = styled("div")({
  display: "flex",
  "@media(max-width: 768px)": {
    display: "block"
  }
})

export const CheckboxItemContainer = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  flex: 1,
  "@media(max-width: 768px)": {
    flexDirection: "column",
    gap: "8px",
    marginTop: "8px"
  }
});

export const VerticalCheckboxItemContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const CheckboxItem = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const CheckboxText = styled("p")({
  fontSize: "14px",
  color: Colors.textPrimary,
});

export const PreviewDesignHeader = styled("div")({
  color: Colors.textTertiary,
  textTransform: "uppercase",
  fontWeight: 700,
  fontSize: "14px",
  marginBottom: "1.5rem",
});
