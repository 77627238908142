import { Colors } from '../../../blocks/utilities/src/Colors';

export const formatCurrency = (amount: string): string => {
  const numAmount = parseFloat(amount);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numAmount);
};

export const getInventoryStatusClass = (inventory: number): React.CSSProperties => {
  if (inventory > 100) {
    return { color: Colors.success, backgroundColor: Colors.successLight, fontWeight: 500 };
  } else if (inventory > 50) {
    return { color: Colors.warning, backgroundColor: Colors.warningLight, fontWeight: 500 };
  } else {
    return { color: Colors.error, backgroundColor: Colors.errorLight, fontWeight: 500 };
  }
};

export const getInventoryStatusText = (inventory: string): string => {
  const inventoryNum = parseInt(inventory);
  if (inventoryNum === 0) return 'Out of Stock';
  if (inventoryNum < 500) return 'Low Stock';
  return 'In Stock';
};
