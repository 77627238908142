import React from "react";
import ProfileController, { globalConfig, Props } from "./ProfileController";
import DashboardLayout from "../../../components/src/dashboard/layout";
import { Button } from "../../../components/src/ui/button";
import { styled } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import PlaceIcon from "@mui/icons-material/Place";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { Label } from "../../../components/src/ui/label";
import { Input } from "../../../components/src/ui/input";
import { Toast } from "../../../components/src/ui/toast";
import { ErrorMessage, Form, Formik } from "formik";
import { TermsConditionsModal } from "../../../components/src/email-account-registration/TermsConditionsModal";
import { PrivacyPolicyModal } from "../../../components/src/email-account-registration/PrivacyPolicyModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { personalDetailsSchema } from "../../../components/src/dashboard/types";

const PageTitle = styled("h2")({
  fontSize: "24px",
  fontWeight: 700,
  color: Colors.textPrimary,
});

const OuterContainer = styled("div")({
  backgroundColor: Colors.bgPrimary,
  padding: "30px 48px",
  height: "100vh",
  "@media (max-width: 768px)": {
    padding: "50px 20px",
  },
});

const AvatarBlock = styled("div")({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  width: "160px",
  height: "160px",
  borderRadius: "50%",
  border: `4px solid ${Colors.bgPrimary}`,
  left: "40px",
  bottom: 0,
  transform: "translateY(50%)",
  "@media (max-width: 768px)": {
    width: "120px",
    height: "120px",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
});

const Avatar = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "50%",
});

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "5rem",
  maxWidth: "900px",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    marginTop: "2rem",
  },
});

const UpperContainer = styled("div")({
  borderRadius: "8px",
  backgroundColor: Colors.white,
});

const LowerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
  backgroundColor: Colors.white,
});

const FunctionalityContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "24px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#F7FAFC",
    transition: "0.2s",
  },
  "&:not(:last-child)": {
    borderBottom: "1px solid #E2E8F0",
  },
});

const FunctionalityText = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "& > span": {
    color: Colors.textPrimary,
    fontSize: "16px",
  },
});

const NameContainer = styled("div")({
  position: "absolute",
  left: "25%",
  bottom: "1rem",
  display: "flex",
  gap: "8px",
  width: "70%",
  alignItems: "center",
  justifyContent: "space-between",
  "@media (max-width: 768px)": {
    bottom: "2rem",
    left: "50%",
    transform: "translateX(-50%)",
    justifyContent: "center",
    "& > button": {
      display: "none",
    },
  },
});

const Name = styled("h3")({
  fontSize: "30px",
  color: Colors.white,
  fontWeight: 700,
  marginBottom: "8px",
  "@media (max-width: 768px)": {
    fontSize: "24px",
    textAlign: "center",
  },
});

const AddressContainer = styled("div")({
  display: "flex",
  alignItems: "start",
  color: Colors.btnSecondary,
  "@media (max-width: 768px)": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const CoverContainer = styled("div")({
  position: "relative",
  height: "200px",
  width: "100%",
  backgroundColor: Colors.btnPrimary,
  borderRadius: "8px 8px 32px 0",
  "@media (max-width: 768px)": {
    height: "300px",
  },
});

const BioContainer = styled("div")({
  paddingLeft: "25%",
  paddingTop: "12px",
  paddingBottom: "32px",
  backgroundColor: Colors.white,
  "@media (max-width: 768px)": {
    paddingLeft: "0",
    paddingBottom: "24px",
    textAlign: "center",
  },
});

const BioHeader = styled("div")({
  textTransform: "uppercase",
  fontSize: "14px",
  fontWeight: 700,
  color: Colors.gray,
});

const BioText = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  fontSize: "16px",
  color: Colors.textPrimary,
  marginTop: "12px",
});

const EditBlock = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "30px 0",
  "&:not(:last-child)": {
    borderBottom: "1px solid #cbd5e1",
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "16px",
    padding: "16px 0",
  },
});

const EditBlockTitle = styled("h3")({
  fontSize: "16px",
  fontWeight: 700,
  color: Colors.textTertiary,
  width: "30%",
  "@media (max-width: 768px)": {
    width: "50%",
  },
});

const PersonalDetailsInputBlock = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  flex: 1,
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  marginTop: "8px",
  flex: 1,
});

const MobileEditBlock = styled("div")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
    "& > button": {
      backgroundColor: "transparent",
      outline: "none",
      border: "none",
    },
  },
});

const StyledFormGroup = styled("div")({
  marginBottom: "24px",
  "@media (max-width: 768px)": {
    marginBottom: "16px",
  },
});

const StyledErrorMessage = styled("p")({
  color: Colors.error,
  fontSize: "12px",
  marginTop: "4px",
});

export default class Profile extends ProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  inputRef = React.createRef<HTMLInputElement>();

  render() {
    // Customizable Area Start
    const {
      isEditMode,
      isTermsModalOpen,
      isPrivacyModalOpen,
      policyText,
      isToastOpen,
      message,
      userDetail,
    } = this.state;

    const { first_name, last_name, full_phone_number, email, salesperson_code, profile_photo_url } = userDetail || {};

    const functionalities = [
      { name: "Password", onClick: this.navigateToChangePasswordScreen },
      { name: "Notification", onClick: undefined },
      {
        name: "Terms and conditions",
        onClick: () => this.handleGetPolicy("terms"),
      },
      {
        name: "Privacy policy",
        onClick: () => this.handleGetPolicy("privacy"),
      },
      { name: "Log out", onClick: this.handleLogout },
    ];

    return (
      <>
        <DashboardLayout>
          <OuterContainer>
            <Row>
              <PageTitle>Profile</PageTitle>
              {isEditMode && (
                <Button
                  data-test-id="save-profile-btn"
                  variant="primary"
                  onClick={() => this.setState({ isEditMode: false })}
                >
                  Exit
                </Button>
              )}
              {!isEditMode && (
                <MobileEditBlock>
                  <button
                    data-test-id="edit-profile-btn"
                    onClick={() => this.setState({ isEditMode: true })}
                  >
                    <EditIcon />
                  </button>
                </MobileEditBlock>
              )}
            </Row>
            {!isEditMode ? (
              <ContentContainer>
                <UpperContainer>
                  <CoverContainer>
                    <AvatarBlock>
                      <Avatar
                        src={globalConfig.baseURL + profile_photo_url}
                        alt="avatar"
                      />
                    </AvatarBlock>
                    <NameContainer>
                      <div>
                        <Name>{first_name} {last_name}</Name>
                        <AddressContainer>
                          <PlaceIcon />
                          <p>California, USA</p>
                        </AddressContainer>
                      </div>
                      <Button
                        variant="secondary"
                        data-test-id="edit-profile-btn"
                        onClick={() => this.setState({ isEditMode: true })}
                        prependIcon={
                          <EditIcon style={{ color: Colors.btnPrimary }} />
                        }
                      >
                        Edit profile
                      </Button>
                    </NameContainer>
                  </CoverContainer>

                  {/* bio */}
                  <BioContainer>
                    <BioHeader>Bio</BioHeader>
                    <BioText>
                      <p>Seneca salesperson {salesperson_code}</p>
                      <p>{full_phone_number}</p>
                    </BioText>
                  </BioContainer>
                </UpperContainer>

                <LowerContainer>
                  {functionalities.map((item, index) => (
                    <FunctionalityContainer
                      key={index}
                      onClick={item.onClick}
                      style={
                        item.name === "Log out"
                          ? { color: Colors.error }
                          : undefined
                      }
                    >
                      <FunctionalityText>
                        <CheckIcon />
                        <span
                          style={
                            item.name === "Log out"
                              ? { color: Colors.error }
                              : undefined
                          }
                        >
                          {item.name}
                        </span>
                      </FunctionalityText>
                      <KeyboardArrowRightIcon />
                    </FunctionalityContainer>
                  ))}
                </LowerContainer>
              </ContentContainer>
            ) : (
              <ContentContainer>
                <EditBlock>
                  <EditBlockTitle>Profile</EditBlockTitle>
                  <Row>
                    <Avatar
                      style={{ width: "132px", height: "132px" }}
                      src={globalConfig.baseURL + profile_photo_url}
                      alt="avatar-edit"
                    />
                    <div>
                      <Button
                        data-test-id="upload-avatar-btn"
                        variant="tertiary"
                        onClick={() => this.inputRef.current?.click()}
                      >
                        Update
                        <input
                          type="file"
                          ref={this.inputRef}
                          accept="image/*"
                          onChange={this.handleAvatarChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <Button variant="tertiary">Delete</Button>
                    </div>
                  </Row>
                </EditBlock>

                <EditBlock>
                  <EditBlockTitle>Personal details</EditBlockTitle>
                  <PersonalDetailsInputBlock>
                    <Formik
                      data-test-id="profile-formik"
                      initialValues={{
                        full_name: `${first_name} ${last_name}`,
                        email: email,
                        phone: full_phone_number,
                        salesperson_code: salesperson_code,
                      }}
                      validationSchema={personalDetailsSchema}
                      onSubmit={this.handleEditPersonalDetails}
                    >
                      {({ values, handleChange, handleBlur }) => (
                        <Form>
                          <StyledFormGroup>
                            <Label htmlFor="full_name">Full name</Label>
                            <Input
                              type="text"
                              id="full_name"
                              name="full_name"
                              value={values.full_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              data-test-id="error-message"
                              name="full_name"
                              render={(msg) => (
                                <StyledErrorMessage>{msg}</StyledErrorMessage>
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              type="email"
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              data-test-id="error-message"
                              name="email"
                              render={(msg) => (
                                <StyledErrorMessage>{msg}</StyledErrorMessage>
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup>
                            <Label htmlFor="phone">Phone</Label>
                            <Input
                              type="text"
                              id="phone"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              data-test-id="error-message"
                              name="phone"
                              render={(msg) => (
                                <StyledErrorMessage>{msg}</StyledErrorMessage>
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup>
                            <Label htmlFor="salesperson_code">Salesperson Code</Label>
                            <Input
                              type="text"
                              id="salesperson_code"
                              name="salesperson_code"
                              value={values.salesperson_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!salesperson_code}
                            />
                            <ErrorMessage
                              data-test-id="error-message"
                              name="salesperson_code"
                              render={(msg) => (
                                <StyledErrorMessage>{msg}</StyledErrorMessage>
                              )}
                            />
                          </StyledFormGroup>

                          <Button
                            data-test-id="update-details-btn"
                            variant="primary"
                            type="submit"
                          >
                            Update
                          </Button>
                        </Form>
                      )}
                    </Formik>
                    <Row>
                      <div>
                        <Label>Password</Label>
                        <Label>*******</Label>
                      </div>
                      <Button
                        variant="tertiary"
                        onClick={this.navigateToChangePasswordScreen}
                      >
                        Change password
                      </Button>
                    </Row>
                  </PersonalDetailsInputBlock>
                </EditBlock>

                <Row>
                  <EditBlockTitle>Account</EditBlockTitle>
                  <Button variant="warning">Delete your account</Button>
                </Row>
              </ContentContainer>
            )}
          </OuterContainer>

          <Snackbar
            open={isToastOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={this.closeToast}
          >
            <Alert
              onClose={this.closeToast}
              severity="success"
              color="success"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </DashboardLayout>

        <Toast
          type="success"
          open={isToastOpen}
          closeToast={this.closeToast}
          message={message}
        />

        <TermsConditionsModal
          open={isTermsModalOpen}
          handleClose={() => this.closeModal("terms")}
          handleAccept={() => {
            this.closeModal("terms");
          }}
          content={policyText}
        />
        <PrivacyPolicyModal
          open={isPrivacyModalOpen}
          handleClose={() => this.closeModal("privacy")}
          handleAccept={() => {
            this.closeModal("privacy");
          }}
          content={policyText}
        />
      </>
    );
    // Customizable Area End
  }
}
