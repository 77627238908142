import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import { Button } from "../button";
import { Colors } from "../../../../blocks/utilities/src/Colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  maxWidth: "800px",
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px 8px 32px 8px",
  boxShadow: 24,
  p: 5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const useStyles = makeStyles({
  title: {
    fontSize: "24px",
    color: Colors.textPrimary,
    fontWeight: 700,
    "@media (max-width: 768px)": {
      fontSize: "20px",
    },
  },
  subtitle: {
    color: Colors.textSecondary,
    fontWeight: 400,
    marginTop: "16px",
    "@media (max-width: 768px)": {
      fontSize: "14px",
    },
  },
  image: {
    width: "50%",
    height: "auto",
    margin: "1rem auto",
  },
});

interface SuccessModalProps {
  open: boolean;
  title: string;
  illustrationImage: any;
  description: string;
  handleClick: () => void;
}

export const SuccessModal = ({
  open,
  title,
  illustrationImage,
  description,
  handleClick,
}: SuccessModalProps) => {
  const styles = useStyles();

  return (
    <Modal
      open={open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <img src={illustrationImage} alt="" className={styles.image} />
          <div style={{ textAlign: "center" }}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.subtitle}>{description}</p>
          </div>

          <Divider sx={{ my: 4 }} />
          <Button variant="primary" onClick={handleClick}>
            Done
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};
