import React from "react";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { makeStyles } from "@mui/styles";
// @ts-ignore
import ProjectDetailsDrawer from "./ProjectDetailsDrawer";
import {ExistingQuotes} from "./types";

const useStyles = makeStyles({
  popoverItem: {
    padding: "12px 16px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.btnSecondary,
    },
  },
  popoverText: {
    fontSize: "14px",
    color: Colors.textPrimary,
  },
  popoverTrigger: {
    display: "flex",
    justifyContent: "center",
  },
});

export default function ProjectDropdownMenu({details}: {details: ExistingQuotes}) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    handleClose();
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div onClick={handleClick} className={styles.popoverTrigger}>
        <MoreVertIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.popoverItem}>
          <OpenInNewIcon
            style={{ color: Colors.textPrimary, fontSize: "16px" }}
          />
          <p className={styles.popoverText} onClick={handleDrawerOpen}>
            View quotes
          </p>
        </div>
        <div className={styles.popoverItem}>
          <EditIcon style={{ color: Colors.textPrimary, fontSize: "16px" }} />
          <p className={styles.popoverText}>Edit</p>
        </div>
        <div className={styles.popoverItem}>
          <DeleteIcon style={{ color: Colors.textPrimary, fontSize: "16px" }} />
          <p className={styles.popoverText}>Delete</p>
        </div>
      </Popover>
      <ProjectDetailsDrawer open={drawerOpen} closeDrawer={handleDrawerClose} details={details} />
    </div>
  );
}
