import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest } from "../../../components/src/utils/api";
import { ExistingQuotes } from "../../../components/src/dashboard/types";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  existingQuotes: ExistingQuotes[];
  token: string;
  errorMsg: string;
  loading: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getExistingQuoteRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      existingQuotes: [],
      errorMsg: "",
      token: "",
      loading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getExistingQuoteRequestId) {
          this.setState({
            existingQuotes: responseJson,
            loading: false,
          });
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleGetExistingQuotes();
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetExistingQuotes = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getExistingQuoteRequestId = requestMessage.messageId;

    createRequest({
      requestMsg: requestMessage,
      endPoint: configJSON.getExistingQuotesEndpoint,
      method: "GET",
    })
  }
  // Customizable Area End
}
