import React from "react";
// Customizable Area Start
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";
import DashboardLayout from "../../../components/src/dashboard/layout";
import { styled } from "@mui/styles";
import { Colors } from "../../utilities/src/Colors";
import BarnType from "../../../components/src/dashboard/BarnType";
import ProductCategories from "../../../components/src/dashboard/ProductCategories";
import ProjectTableView from "./ProjectTableView.web";

const StyledTitle = styled("h1")({
  fontSize: "24px",
  color: Colors.textPrimary,
  lineHeight: "32px",
  fontWeight: 700,
  marginBottom: "30px",
  "@media (max-width: 768px)": {
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    marginBottom: "20px",
  }
});

const DashboardContainer = styled("main")({
  padding: "30px 48px",
  backgroundColor: Colors.bgPrimary,
  "@media (max-width: 768px)": {
    padding: "30px 20px",
  }
});

const SectionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
});

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {existingQuotes} = this.state
    return (
      <DashboardLayout>
        <DashboardContainer>
          <StyledTitle>Seneca Dairy System</StyledTitle>
          <SectionContainer>
            <BarnType navigation={this.props.navigation} />
            <ProductCategories navigation={this.props.navigation} />
            <ProjectTableView data={existingQuotes} />
          </SectionContainer>
        </DashboardContainer>
      </DashboardLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
