import React from "react";
import { styled } from "@mui/styles";
import { Colors } from "../../../../blocks/utilities/src/Colors";

const StyledCheckbox = styled("input")({
  appearance: "none",
  width: "20px",
  height: "20px",
  marginRight: "8px",
  border: "1px solid #64748B",
  borderRadius: "6px",
  backgroundColor: Colors.backgroundLight,
  cursor: "pointer",
  position: "relative",

  "&:checked": {
    backgroundColor: Colors.btnPrimary,
    borderColor: Colors.btnPrimary,
  },

  "&:checked::after": {
    content: '""',
    position: "absolute",
    top: "43%",
    left: "50%",
    width: "5px",
    height: "9px",
    transform: "translate(-50%, -50%) rotate(45deg)",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
  },
});

const StyledRadio = styled("input")({
  appearance: "none",
  width: "20px",
  height: "20px",
  marginRight: "8px",
  border: "1px solid #64748B",
  borderRadius: "50%",
  backgroundColor: Colors.backgroundLight,
  cursor: "pointer",
  position: "relative",

  "&:checked": {
    backgroundColor: Colors.btnPrimary,
    borderColor: Colors.btnPrimary,
  },

  "&:checked::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "8px",
    height: "8px",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    backgroundColor: "#fff"
  },
});

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

const BaseCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return <StyledCheckbox type="checkbox" ref={ref} {...props} />;
});

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return <StyledCheckbox type="radio" ref={ref} {...props} />;
});

export { BaseCheckbox, Checkbox, Radio };
const Radio = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return <StyledRadio type="radio" ref={ref} {...props} />;
});

Checkbox.displayName = "Checkbox";
BaseCheckbox.displayName = "BaseCheckbox";
Radio.displayName = "Radio";

