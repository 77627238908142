import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest } from "../../../components/src/utils/api";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface TInventoryItem {
  id: string;
  type: string;
  attributes: {
    key: string;
    no: string;
    description: string;
    type: string;
    base_unit_of_measure: string;
    standard_cost: string;
    avg_material_cost: string;
    last_direct_cost: string;
    unit_price: string;
    inventory_posting_group: string;
    search_description: string;
    item_category_code: string;
    product_group_code: string;
    inventory: string;
    variable_1_value: string;
    variable_2_value: string;
    variable_3_value: string;
    variable_4_value: string;
    global_dimension_2_code: string;
    shortcut_dimension_1_code: string;
    shortcut_dimension_2_code: string;
    available_inventory: string;
    net_weight: string;
  };
}


interface S {
  isSubFolderOpen: boolean;
  inventoryList: TInventoryItem[];
  isLoading: boolean;
  searchQuery: string;
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getCatalogApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      isSubFolderOpen: false,
      inventoryList: [],
      isLoading: false,
      searchQuery: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getCatalogApiCallId) {
          this.setState({
            isLoading: false,
            inventoryList: responseJson.data
          });
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getCatalogData();
    // Customizable Area End
  }

  // Customizable Area Start
  goToProductDetail = (id: number) => {
    this.props.navigation.navigate("CatalogDetail", {
      path: {
        id: id
      }
    })
  }

  getCatalogData = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCatalogApiCallId = requestMessage.messageId;

    createRequest({
      requestMsg: requestMessage,
      endPoint: '/erp_items',
      method: "GET",
    })
  };

  handleSearch = (value: string) => {
    this.setState({ searchQuery: value });
  };
  // Customizable Area End
}
