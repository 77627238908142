import * as React from "react";
import * as Yup from "yup";

export type Order = "asc" | "desc";

export interface HeadCell {
  disablePadding: boolean;
  id: keyof ExistingQuotes;
  label: string;
  numeric: boolean;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ExistingQuotes
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export interface ExistingQuotes {
  id: number;
  barn_type: string;
  client: string;
  amount: string;
  status: "sent" | "in_progress" | "completed"
  created_at: string;
}

export const changePasswordValidationSchema = Yup.object({
  currentPassword: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
    .required("Password confirmation is required"),
});

export const personalDetailsSchema = Yup.object({
  full_name: Yup.string().required("Cannot leave full name blank"),
  email: Yup.string().email("Invalid email address").required("Cannot leave email blank"),
  phone: Yup.string().nullable().matches(/^\d{10,12}$/, "Phone number must be between 10 and 12 digits"),
  salesperson_code: Yup.string().required("Cannot leave salesperson code blank"),
})
