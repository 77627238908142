import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest } from "../../../components/src/utils/api";
import { getFromStorage } from "../../../components/src/utils/string";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}
interface S {
  // Customizable Area Start
  isSubmitting: boolean;
  errorMsg: string;
  isToastOpen: boolean;
  // Customizable Area End
}
interface SS {}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePasswordRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isSubmitting: false,
      errorMsg: "",
      isToastOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          this.setState({
            isSubmitting: false,
            errorMsg:
              responseJson.errors[0].password ??
              responseJson.errors[0].current_password,
          });
          return;
        }

        if (apiRequestCallId === this.changePasswordRequestId) {
          this.setState({ isSubmitting: false, isToastOpen: true }, () => {
            setTimeout(() => {
              this.props.navigation.navigate("Profile");
            }, 1000);
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  changePassword = (values: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    const { currentPassword, newPassword } = values;
    this.setState({ isSubmitting: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordRequestId = requestMessage.messageId;

    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
    };

    createRequest({
      token: getFromStorage("token") ?? "",
      requestMsg: requestMessage,
      endPoint: `${configJSON.personalDetailsEndpoint}/${getFromStorage(
        "userId"
      )}/change_password`,
      method: "PATCH",
      body: JSON.stringify(payload),
    });
  };

  closeToast = () => {
    this.setState({ isToastOpen: false });
  };
  // Customizable Area End
}
