import {
  Box,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GroupAdd, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Colors } from "../../../../blocks/utilities/src/Colors";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      color: Colors.textTertiary,
      fontFamily: "Inter",
    },
    "& .MuiMenu-paper": {
      marginTop: "16px",
    },
  },
  input: {
    "& .MuiOutlinedInput-input": {
      fontFamily: "Inter",
      padding: "12px 12px 12px 4px",
    },
  },
});

export const ChooseClientDropdown = ({
  onCreateNewClient,
}: {
  onCreateNewClient: () => void;
}) => {
  const styles = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const filteredClients = ["Client 1", "Client 2", "Client 3"];

  return (
    <Select
      fullWidth
      value={""}
      displayEmpty
      renderValue={(value) => (value ? value : "New client")}
      className={styles.root}
    >
      {/* Search Input */}
      <MenuItem>
        <div onClick={(e) => e.stopPropagation()} style={{ width: "100%" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search client here"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className={styles.input}
          />
        </div>
      </MenuItem>

      {/* Create New Client */}
      <MenuItem onClick={onCreateNewClient}>
        <ListItemIcon>
          <GroupAdd />
        </ListItemIcon>
        <ListItemText primary="Create new Client" />
      </MenuItem>

      {/* List of Clients */}
      {filteredClients.map((client, index) => (
        <MenuItem
          key={index}
          value={client}
          onClick={() => {}}
          style={{ padding: "12px 16px" }}
        >
          <ListItemIcon>
            <Box
              sx={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: "#D1E9FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                color: "#A666FF",
              }}
            >
              W
            </Box>
          </ListItemIcon>
          <ListItemText primary={client} />
        </MenuItem>
      ))}
    </Select>
  );
};
