import React from "react";
import { styled } from "@mui/styles";
import { ModalCore } from "../ui/modal";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { Button } from "../ui/button";
import parse from "html-react-parser";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  content: string;
}

const StyledHeader = styled("h3")({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: Colors.textPrimary,
  "@media (max-width: 768px)": {
    fontSize: "1.25rem",
  },
});

const commonStyles = {
  color: Colors.textPrimary,
  marginTop: "16px",
  fontSize: "1rem",
  lineHeight: "1.35",
  "@media (max-width: 768px)": {
    fontSize: "0.875rem",
  },
};

const StyledParagraph = styled("p")(commonStyles);

const HorizontalLines = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E5E5",
  margin: "24px 0",
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "24px",
});

export const PrivacyPolicyModal = ({ open, handleClose, handleAccept, content }: Props) => {
  return (
    <ModalCore open={open} handleClose={handleClose}>
      <div>
        <StyledHeader>Privacy Policy</StyledHeader>
        <StyledParagraph>{content && parse(content)}</StyledParagraph>

        <HorizontalLines />

        <ButtonContainer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAccept}>
            Accept
          </Button>
        </ButtonContainer>
      </div>
    </ModalCore>
  );
};
