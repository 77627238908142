import ProjectDetailsDrawerController, {
  Props,
  // @ts-ignore
} from "./ProjectDetailsDrawerController";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Close from "@mui/icons-material/Close";
import { Button } from "../ui/button";
import { Colors } from "../../../blocks/utilities/src/Colors";

const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    width: "500px",
    padding: "30px",
    boxSizing: "border-box",
    "@media(max-width: 480px)": {
      width: "100%"
    }
  },
});

const ProductImage = styled("img")({
  width: "100px",
  height: "auto",
  objectFit: "cover",
  borderRadius: "8px",
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "8px 0",
});

const Header = styled(Row)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});

const Section = styled("div")({
  marginBottom: "8px",
});

const SectionTitle = styled("h4")({
  color: "#334155",
  fontSize: "14px",
  fontWeight: 700,
  margin: "16px 0",
});

const Label = styled("div")({
  color: Colors.textTertiary,
  marginBottom: "8px",
});

const Value = styled("p")({
  color: Colors.textPrimary,
  fontWeight: 700,
  marginBottom: "8px",
});

const Footer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  marginTop: "auto",
});

export default class ProjectDetailsDrawer extends ProjectDetailsDrawerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, closeDrawer, details } = this.props;

    return (
      <StyledDrawer anchor="right" open={open} onClose={closeDrawer}>
        {/* Header */}
        <Header>
          <Typography variant="h6" fontWeight="bold">
            Project details {details?.projectType}
          </Typography>
          <Close onClick={closeDrawer} style={{ cursor: "pointer" }} />
        </Header>

        <Divider />

        {/* Content */}
        <Section>
          <SectionTitle>Items</SectionTitle>
          <Row>
            <Row>
              <ProductImage
                src="https://via.placeholder.com/80" // Replace with your image URL
              />
              <div style={{ marginLeft: "8px" }}>
                <Label>Gates</Label>
                <Value>MANPASS</Value>
                <Label>Qty: 1</Label>
              </div>
            </Row>
            <Value>$3,500.00</Value>
          </Row>
        </Section>

        <Divider />

        <Section>
          <Row>
            <Label>Created</Label>
            <Value>12.23.2024</Value>
          </Row>
          <Row>
            <Label>Status</Label>
            <Value>Completed</Value>
          </Row>
        </Section>

        <Divider />

        <Section>
          <SectionTitle>Client</SectionTitle>
          <Row>
            <Label>Customer</Label>
            <Value>Mary Johnson</Value>
          </Row>
          <Row>
            <Label>Email</Label>
            <Value>mj@gmail.com</Value>
          </Row>
          <Row>
            <Label>Phone</Label>
            <Value>+1-555-490-4567</Value>
          </Row>
        </Section>

        <Divider />

        <Section>
          <SectionTitle>Project specification</SectionTitle>
          <Row>
            <Label>Subtotal</Label>
            <Value>$3,500.00</Value>
          </Row>
          <Row>
            <Label>Shipping fee</Label>
            <Value>$500.00</Value>
          </Row>
          <Row>
            <Label>Total</Label>
            <Value>$4,000.00</Value>
          </Row>
        </Section>

        {/* Footer */}
        <Footer>
          <Button variant="secondary">View Details</Button>
          <Button variant="primary">Copy template</Button>
        </Footer>
      </StyledDrawer>
    );
  }
}
