import React from "react";
// Customizable Area Start
import CatalogueDetailController, { Props } from "./CatalogueDetailController";
import DashboardLayout from "../../../components/src/dashboard/layout";
import { styled } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { Grid, Stack, Chip, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import {
  Breadcrumbs,
  BreadcrumbLink
} from "../../../components/src/ui/breadcrumbs";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// Customizable Area End

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/"
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/"
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/"
  }
];

const CatalogDetailContainer = styled("main")({
  padding: "30px 48px",
  minHeight: "100vh",
  backgroundColor: Colors.bgPrimary,
  "@media (max-width: 768px)": {
    padding: "30px 20px"
  }
});

const ContentContainer = styled("div")({
  backgroundColor: Colors.white,
  borderRadius: "8px 8px 32px 8px",
  marginTop: "30px"
});

const HeadingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  color: Colors.textPrimary,
  "& > h2": {
    fontSize: "24px",
    fontWeight: 700
  },
  padding: "24px 40px",
  "@media(max-width: 768px)": {
    padding: "18px 30px",
    "& > h2": {
      fontSize: "18px"
    }
  }
});

const BodyContainer = styled("div")({
  padding: "24px 40px",
  "@media(max-width: 768px)": {
    padding: "18px 30px"
  }
});

const BodyHeadingBlock = styled("div")({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  "@media(max-width: 768px)": {
    width: "100%"
  }
});

const SubTitle = styled("h4")({
  color: Colors.textPrimary,
  textTransform: "uppercase",
  fontSize: "14px"
});

const Title = styled("h3")({
  fontSize: "20px",
  fontWeight: 700,
  color: Colors.textPrimary
});

const Price = styled("div")({
  fontSize: "24px",
  fontWeight: 800,
  color: Colors.btnPrimary
});

const Description = styled("p")({
  color: Colors.textTertiary,
  lineHeight: "1.5rem"
});

const SectionTitle = styled("h4")({
  fontWeight: 700,
  color: Colors.textPrimary,
  "@media(max-width: 768px)": {
    fontSize: "14px"
  }
});

const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    backgroundColor: Colors.bgPrimary,
    padding: "8px"
  },
  "& > span": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    color: Colors.textPrimary
  }
});

const Avatar = styled("div")({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: Colors.gray,
  border: `1px solid ${Colors.gray}`,
  "@media(max-width: 768px)": {
    display: "none"
  }
});

const StyledDisplayImage = styled("img")({
  aspectRatio: "3/4",
  objectFit: "cover !important" as "cover",
  borderRadius: "12px",
  marginBottom: "2rem"
});

const sizeVariants = [
  { label: "2’ 6” through 4’ in 6” increments" },
  { label: "4’ 3” through 15’ 9” in 3” increments" },
  { label: "16’ or larger sizing in 6” increments" }
];

const features = [
  "All of our 4 and 5 bar gates are made with galvanized steel. 2”x2” 12 gauge ends and 1.900 13 gauge round tubes.",
  "All gates over 10’ have center supports.",
  "Standard nipple for latch is 1.900 × 12 gauge galvanized steel.",
  "Universal latches allow you to decide open direction at installation.",
  "Man-pass gates feature a large 1’ x 5’ opening for easy passage."
];

export default class CatalogueDetail extends CatalogueDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderLeftNav = (
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    disabled: boolean
  ) => (
    <button
      className="image-gallery-icon image-gallery-left-nav"
      onClick={onClick}
      disabled={disabled}
    >
      <ChevronLeftIcon style={{ fontSize: "2rem" }} />
    </button>
  );

  renderRightNav = (
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    disabled: boolean
  ) => (
    <button
      className="image-gallery-icon image-gallery-right-nav"
      onClick={onClick}
      disabled={disabled}
    >
      <ChevronRightIcon style={{ fontSize: "2rem" }} />
    </button>
  );

  renderFullscreenButton = (
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    isFullscreen: boolean
  ) => (
    <button
      type="button"
      style={{ bottom: "2rem" }}
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={onClick}
    >
      {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </button>
  );

  render() {
    return (
      <DashboardLayout>
        <CatalogDetailContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem"
            }}
          >
            <Breadcrumbs>
              <BreadcrumbLink label="Dashboard" href="/" />
              <BreadcrumbLink label="Catalogue" href="/catalog" />
              <BreadcrumbLink label="Catalogue Detail View" />
            </Breadcrumbs>
            <Avatar
              data-test-id="profile-btn"
              onClick={() => this.props.navigation.navigate("Profile")}
            />
          </div>
          <ContentContainer>
            <HeadingContainer>
              <ArrowBackIcon
                data-test-id="arrow-back"
                onClick={() => this.props.navigation.goBack()}
              />
              <h2>Standard & Man - Pass Gates</h2>
            </HeadingContainer>

            <Divider style={{ color: Colors.gray }} />

            <BodyContainer>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <ImageGallery
                    data-test-id="image-gallery"
                    items={images}
                    showPlayButton={false}
                    renderItem={item => (
                      <StyledDisplayImage
                        src={item.original}
                        className="image-gallery-image"
                      />
                    )}
                    renderLeftNav={(onClick, disabled) =>
                      this.renderLeftNav(onClick, disabled)
                    }
                    renderRightNav={(onClick, disabled) =>
                      this.renderRightNav(onClick, disabled)
                    }
                    renderFullscreenButton={(onClick, isFullscreen) =>
                      this.renderFullscreenButton(onClick, isFullscreen)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <BodyHeadingBlock>
                    <SubTitle>Gates</SubTitle>
                    <Title>Standard & Man-Pass Gate</Title>
                    <Description>
                      Follow in the footsteps of greatness. The all-time classic
                      AJ1 design gets a makeover with fresh pops of colour and
                      mixed materials.
                    </Description>
                    <Price>Cost: $2202</Price>
                    <Divider style={{ color: Colors.gray }} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px"
                      }}
                    >
                      <SectionTitle>Quality on hand:</SectionTitle>
                      <Description>659</Description>
                    </div>
                    <Divider style={{ color: Colors.gray }} />
                  </BodyHeadingBlock>

                  <div style={{ margin: "16px 0" }}>
                    <SectionTitle>Size:</SectionTitle>
                    <Stack
                      direction="row"
                      style={{
                        marginTop: "1rem",
                        flexWrap: "wrap",
                        gap: "1rem"
                      }}
                    >
                      {sizeVariants.map((size, index) => (
                        <StyledChip key={index} label={size.label} />
                      ))}
                    </Stack>
                  </div>

                  <Divider style={{ color: Colors.gray, width: "50%" }} />

                  <div style={{ margin: "16px 0" }}>
                    <SectionTitle>Feature:</SectionTitle>
                    <ul
                      style={{
                        marginTop: "1rem",
                        flexWrap: "wrap",
                        gap: "1rem"
                      }}
                    >
                      {features.map((item, index) => (
                        <Description key={index}>
                          <li>{item}</li>
                        </Description>
                      ))}
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </BodyContainer>
          </ContentContainer>
        </CatalogDetailContainer>
      </DashboardLayout>
    );
  }
}

// Customizable Area Start
// Customizable Area End
