import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export interface Props {
  category: string;
  open: boolean;
  closeModal: () => void;
  navigation: any;
}

export interface S {
  // Customizable Area Start
  addModalOpen: boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ChooseClientModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyEmailRequestId: any;
  resetPasswordRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      addModalOpen: false,
      // Customizable Area End
    };
  }

  // Customizable Area Start
  openModal = () => {
    this.setState({ addModalOpen: true });
  };

  closeModal = () => {
    this.setState({ addModalOpen: false });
    this.props.closeModal()
  };

  redirectToCustomization = () => {
  const formattedCategory = this.props.category.toLowerCase().replace("/", "-").replace(/ /g, "-");
    this.props.navigation.navigate("ProductCategories", {
      path: {
        category: formattedCategory,
      },
      state: {
        category: formattedCategory,
      }
    });
  }
  // Customizable Area End
}
