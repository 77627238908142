import React from "react";
// Customizable Area Start
// Customizable Area End
import ProjectTableViewController, {
  Props,
} from "./ProjectTableViewController.web";
import ProjectTable from "../../../components/src/dashboard/ProjectTable";
import { styled } from "@mui/styles";
import { Colors } from "../../utilities/src/Colors";
import { ProjectFilterDropdown } from "../../../components/src/dashboard/dropdown/ProjectFilterDropdown";

const SectionHeadingContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "24px",
});

const SectionHeading = styled("h3")({
  fontSize: "12px",
  color: Colors.textTertiary,
  fontWeight: 600,
  textTransform: "uppercase",
});

export default class ProjectTableView extends ProjectTableViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const filters = this.state.filters;

    // istanbul ignore next
    const filteredData = this.props.data.filter((project) => {
      const statusMatch = filters.status.length === 0 || filters.status.includes(project.status);
      const barnTypeMatch = filters.barnType.length === 0 || filters.barnType.includes(project.barn_type);
      const projectTypeMatch = filters.projectType.length === 0 || filters.projectType.includes(project.barn_type);
      return statusMatch && barnTypeMatch && projectTypeMatch;
    });

    return (
      <div>
        <SectionHeadingContainer>
          <SectionHeading>projects</SectionHeading>
          <ProjectFilterDropdown filters={filters} onFilterChange={this.handleFilterChange} />
        </SectionHeadingContainer>
        <ProjectTable data={filteredData} />
      </div>
    );
    // Customizable Area End
  }
}
