import React from "react";
import ChangePasswordController, { Props } from "./ChangePasswordController";
import DashboardLayout from "../../../components/src/dashboard/layout";
import { Button } from "../../../components/src/ui/button";
import { styled } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { Label } from "../../../components/src/ui/label";
import { Input } from "../../../components/src/ui/input";
import { ErrorMessage, Form, Formik } from "formik";
import { Spinner } from "../../../components/src/ui/spinner";
import { Toast } from "../../../components/src/ui/toast";
import { changePasswordValidationSchema } from "../../../components/src/dashboard/types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PageTitle = styled("h2")({
  fontSize: "24px",
  fontWeight: 700,
  color: Colors.textPrimary,
  marginLeft: "1rem",
});

const OuterContainer = styled("div")({
  backgroundColor: Colors.bgPrimary,
  padding: "30px 48px",
  height: "100vh",
});

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "5rem",
  maxWidth: "900px",
  margin: "0 auto",
});

const StyledErrorMessage = styled("p")({
  color: Colors.error,
  fontSize: "12px",
  marginTop: "4px",
});

const PersonalDetailsInputBlock = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  flex: 1,
});

const FormGroup = styled("div")({
  marginBottom: "24px",
});

export default class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { isSubmitting, errorMsg, isToastOpen } = this.state;

    return (
      <>
        <DashboardLayout>
          <OuterContainer>
            <div style={{ display: "flex" }}>
              <ArrowBackIcon
                data-test-id="go-back-btn"
                onClick={() => this.props.navigation.goBack()}
              />
              <PageTitle>Change Password</PageTitle>
            </div>
            <ContentContainer>
              <PersonalDetailsInputBlock>
                <Formik
                  data-test-id="change-password-formik"
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={changePasswordValidationSchema}
                  onSubmit={this.changePassword}
                  validateOnChange
                >
                  {({ values, touched, handleChange, errors, handleBlur }) => (
                    <Form>
                      <FormGroup>
                        <Label htmlFor="currentPassword">
                          Current password
                        </Label>
                        <Input
                          name="currentPassword"
                          id="currentPassword"
                          value={values.currentPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            !!errors.currentPassword && touched.currentPassword
                          }
                          placeholder="Enter current password"
                          showToggle
                        />
                        <ErrorMessage
                          data-test-id="error-message"
                          name="currentPassword"
                          render={(msg) => (
                            <StyledErrorMessage>{msg}</StyledErrorMessage>
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="newPassword">New password</Label>
                        <Input
                          name="newPassword"
                          id="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter new password"
                          isInvalid={
                            !!errors.newPassword && touched.newPassword
                          }
                          showToggle
                        />
                        <ErrorMessage
                          data-test-id="error-message"
                          name="newPassword"
                          render={(msg) => (
                            <StyledErrorMessage>{msg}</StyledErrorMessage>
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="confirmPassword">
                          Confirm password
                        </Label>
                        <Input
                          name="confirmPassword"
                          id="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            !!errors.confirmPassword && touched.confirmPassword
                          }
                          placeholder="Confirm password"
                          showToggle
                        />
                        <ErrorMessage
                          data-test-id="error-message"
                          name="confirmPassword"
                          render={(msg) => (
                            <StyledErrorMessage>{msg}</StyledErrorMessage>
                          )}
                        />
                      </FormGroup>
                      {errorMsg && (
                        <StyledErrorMessage style={{ marginBottom: "4px" }}>
                          {errorMsg}
                        </StyledErrorMessage>
                      )}
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !values.currentPassword ||
                          !values.newPassword ||
                          !values.confirmPassword ||
                          values.newPassword !== values.confirmPassword
                        }
                      >
                        {isSubmitting ? (
                          <Spinner height="20px" width="20px" />
                        ) : (
                          "Change password"
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </PersonalDetailsInputBlock>
            </ContentContainer>
          </OuterContainer>

          <Toast
            type="success"
            open={isToastOpen}
            closeToast={this.closeToast}
            message="Password changed successfully!"
          />
        </DashboardLayout>
      </>
    );
  }
}
