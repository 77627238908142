import Grid from "@mui/material/Grid";
import { Label } from "../ui/label";
import {
  INTERNAL_TRAVEL_LANE,
  TRAVEL_LANE_POSITION,
  TRAVEL_LANE_POSITION_3,
} from "../constants/categories";
import { Checkbox } from "../ui/input/checkbox";
import { CustomCounter } from "../dashboard/Counter";
import { Input } from "../ui/input";
import {
  FieldContainer,
  HorizontalField,
  CheckboxItemContainer,
  CheckboxText,
  CheckboxItem,
  VerticalCheckboxItemContainer,
} from "./style";
import React, { ChangeEvent } from "react";

interface Props {
  values: any;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  cowNumber: number;
  handleCowNumberChange: (cowNum: number) => void;
}

export default function ThirdStepForm({
  values,
  handleChange,
  cowNumber,
  handleCowNumberChange,
}: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <HorizontalField>
            <Label style={{ marginBottom: 0, flex: 1 }}>
              Does the barn have an internal travel lane?
            </Label>
            <CheckboxItemContainer>
              {INTERNAL_TRAVEL_LANE.map((item) => (
                <CheckboxItem key={item.key}>
                  <Checkbox
                    name="internal_travel_lane"
                    checked={item.key === values.internal_travel_lane}
                    value={item.key}
                    onChange={handleChange}
                  />
                  <CheckboxText>{item.title}</CheckboxText>
                </CheckboxItem>
              ))}
            </CheckboxItemContainer>
          </HorizontalField>
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <HorizontalField>
            <Label style={{ marginBottom: 0, flex: 1 }}>
              Width of travel lane (in inches)
            </Label>
            <CustomCounter value={cowNumber} onChange={handleCowNumberChange} />
          </HorizontalField>
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Is the travel lane at the end or center of the barn?</Label>
          <VerticalCheckboxItemContainer>
            {TRAVEL_LANE_POSITION.map((item) => (
              <CheckboxItem key={item.key}>
                <Checkbox
                  name="internal_travel_lane"
                  checked={item.key === values.internal_travel_lane}
                  value={item.key}
                  onChange={handleChange}
                />
                <CheckboxText>{item.title}</CheckboxText>
              </CheckboxItem>
            ))}
          </VerticalCheckboxItemContainer>
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Feed lane / Scrape alley width</Label>
          <Input
            type="text"
            name="feed_lane"
            value={values.internal_travel_lane}
            onChange={handleChange}
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>
            Width of crosswalks, each be editable or could be all the same
          </Label>
          <Input
            type="text"
            name="crosswalks_width"
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Rear Scrap alley width</Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Width of cross overs</Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Center stall bed total width (Outside of curb)</Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Width of walkway at end of barn</Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>
            Outside Row stall bed width (Outside of barn post to alley side of
            curb)
          </Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Width of walkway at other end of barn</Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>
            # of End Crosswalks (Used when loops are on one side only)
          </Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label>Is the travel lane at the end or center of the barn</Label>
          <VerticalCheckboxItemContainer>
            {TRAVEL_LANE_POSITION_3.map((item) => (
              <CheckboxItem key={item.key}>
                <Checkbox
                  name="internal_travel_lane"
                  checked={item.key === values.internal_travel_lane}
                  value={item.key}
                  onChange={handleChange}
                />
                <CheckboxText>{item.title}</CheckboxText>
              </CheckboxItem>
            ))}
          </VerticalCheckboxItemContainer>
        </FieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldContainer>
          <Label># of center cross overs</Label>
          <Input
            type="text"
            value={values.internal_travel_lane}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </FieldContainer>
      </Grid>
    </Grid>
  );
}
