// App.js - WEB
import React from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import Catalogue from '../../blocks/catalogue/src/Catalogue.web';
import CatalogDetail from '../../blocks/catalogue/src/CatalogDetail.web';
import ProductCategories from '../../blocks/dashboard/src/ProductCategories.web';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountLogin from '../../blocks/email-account-registration/src/EmailAccountLogin.web';
import ForgotPassword from '../../blocks/email-account-registration/src/ForgotPassword.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import CustomizeBarn from '../../blocks/dashboard/src/CustomizeBarn.web';
import Profile from "../../blocks/dashboard/src/Profile.web";
import HomeScreen from "../../components/src/HomeScreen";
import ChangePassword from "../../blocks/dashboard/src/ChangePassword.web";

const routeMap = {
  SignUp: {
    component: EmailAccountRegistration,
    path: '/sign-up',
  },
  Login: {
    component: EmailAccountLogin,
    path: '/login',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgot-password',
  },
  Home: {
    component: Dashboard,
    path: '/',
    exact: true,
  },
  Catalog: {
    component: Catalogue,
    path: '/catalog',
  },
  CatalogDetail: {
    component: CatalogDetail,
    path: '/catalog/:id'
  },
  ProductCategories: {
    component: ProductCategories,
    path: '/dashboard/product-categories/:category',
  },
  CustomizeBarn: {
    component: CustomizeBarn,
    path: '/dashboard/:option',
  },
  Profile: {
    component: Profile,
    path: '/profile',
  },
  ChangePassword: {
    component: ChangePassword,
    path: '/profile/change-password',
  },
  HomeScreen: {
    component: HomeScreen,
    path: '/home-screen',
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;
