import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface ToastProps {
  type: "success" | "error";
  open: boolean;
  closeToast?: () => void;
  message?: string;
}

export const Toast = ({ type, open, closeToast, message }: ToastProps) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={3000}
      onClose={closeToast}
    >
      <Alert
        onClose={closeToast}
        severity={type}
        color={type}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}